import { faDiscord } from '@fortawesome/free-brands-svg-icons/faDiscord'
import { faFacebook } from '@fortawesome/free-brands-svg-icons/faFacebook'
import { faFacebookF } from '@fortawesome/free-brands-svg-icons/faFacebookF'
import { faGoogle } from '@fortawesome/free-brands-svg-icons/faGoogle'
import { faInstagram } from '@fortawesome/free-brands-svg-icons/faInstagram'
import { faTwitter } from '@fortawesome/free-brands-svg-icons/faTwitter'
import { faVimeo } from '@fortawesome/free-brands-svg-icons/faVimeo'
import { faYoutube } from '@fortawesome/free-brands-svg-icons/faYoutube'

let icons = []
icons.push(faDiscord)
icons.push(faFacebook)
icons.push(faFacebookF)
icons.push(faGoogle)
icons.push(faInstagram)
icons.push(faTwitter)
icons.push(faVimeo)
icons.push(faYoutube)

export default icons
