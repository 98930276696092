import Tribute from "tributejs"

const $ = jQuery;

const Cls = (window.Mention = class Mention {
  static initClass() {
    this.attach = () => {
      return $("[data-behavior~=mention]").each(this.attachOne);
    };

    this.attachOne = (index, element) => {
      return this.instance($(element)).attach();
    };
  }

  static instance(element) {
    if (!element.data('mention')) { element.data('mention', new Mention(element)); }
    return element.data('mention');
  }

  constructor(element) {
    this.element = $(element);
  }

  attach() {
    this.tribute = new Tribute(this.config());
    return this.tribute.attach(this.element[0]);
  }

  config() {
    return {
      values: this.suggests(),
      lookup: 'name',
      fillAttr: 'name'
    };
  }

  suggests() {
    const users = jQuery.unique(
      Array.from(document.querySelectorAll('.user-name'))
        .map(item => item.text)
        .sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))
    )
      .map(item => ({
      name: item
    }));
    return users;
  }
});
Cls.initClass();

$(() => Mention.attach());

