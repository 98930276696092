/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const $ = jQuery;

window.Comment = class Comment {
  constructor(id, commentableId, rootId) {
    this.hideEditForm = this.hideEditForm.bind(this);
    this.hideForm = this.hideForm.bind(this);
    this.id = id;
    this.commentableId = commentableId;
    this.rootId = rootId;
  }

  // public methods
  showForm(form) {
    this.form().html(form);
    this.replay().one("click", this.hideForm);
    this.cancelNew().one("click", this.hideForm);

    const textarea = $(this.form().find("textarea"));
    textarea.focus();
    if (textarea.data("user-name")) { textarea.val(`@${textarea.data("user-name")} `); }
    return this.setupMentions(textarea);
  }

  setupMentions(textarea) {
    return Mention.instance(textarea).attach();
  }

  showEditForm(form) {
    this.content().html(form);
    const textarea = this.content().find("textarea");
    textarea.focus();
    this.setupMentions(textarea);
    return this.edit().one("click", this.hideEditForm);
  }

  hideEditForm() {
    if (this.cancelEdit().length === 0) { return true; }

    this.cancelEdit().click();
    return false;
  }

  hideForm() {
    // required for data-remote to work
    if (this.form().html() === "") { return true; }

    this.form().empty();
    return false;
  }

  showComment(comment) {
    return this.content().html(comment);
  }


  addComment(comment) {
    if (this.rootId) {
      this.hideForm();
      this.comments().append(comment);
    } else {
      this.clearForm();
      this.comments().prepend(comment);
    }

    return this.empty().remove();
  }


  removeComment() {
    return this.comment().remove();
  }

  updateVotes(up, down) {
    this.voteUp().html(up);
    return this.voteDown().html(down);
  }

  // private methods
  rootSelector(prefix) {
    return this._selector(prefix, this.rootId);
  }

  selector(prefix) {
    return this._selector(prefix, this.id);
  }

  _selector(prefix, commentId) {
    const selector = [];
    if (prefix) { selector.push(prefix); }
    if (commentId) {
      selector.push("comment");
      selector.push(commentId);
    } else {
      selector.push("commentable");
      selector.push(this.commentableId);
    }

    return `#${selector.join("-")}`;
  }

  clearForm() {
    this.form().find("textarea").val('');
    return this.form().find(".alert").remove();
  }

  form() {
    return $(this.selector('new-comment'));
  }

  empty() {
    return $(this.selector('comments-empty'));
  }

  replay() {
    return $(this.selector('replay'));
  }

  cancelNew() {
    return $(this.selector('cancel-new'));
  }

  cancelEdit() {
    return $(this.selector("cancel-edit"));
  }

  edit() {
    return $(this.selector('edit'));
  }

  comment() {
    return $(this.selector());
  }

  content() {
    return $(this.selector('content'));
  }

  comments() {
    return $(this.rootSelector('comments-new'));
  }

  editFormCancel() {
    return $(this.selector('edit-cancel'));
  }

  voteUp() {
    return $(this.selector('vote-up'));
  }

  voteDown() {
    return $(this.selector('vote-down'));
  }
};

