/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const $ = jQuery;

window.UnconfirmedUser = class UnconfirmedUser {
  constructor(currentUser) {
    this.currentUser = currentUser;
  }

  reload() {
    $("#unconfirmed-user").remove();
    if (this.currentUser.id && !this.currentUser.confirmed && this.currentUser.deliver_emails) {
      return $('body').prepend(this.template());
    }
  }

  template() {
    return `\
<div class="alert alert-announcement alert-warning m-0 text-center" id="unconfirmed-user">
  <div class="container">
    <i name="envelope" class="fa-lg alert-icon me-2" data-controller="icon"></i>
    <strong>${this.currentUser.real_name}</strong>, sprawdź skrzynkę pocztową <strong>${this.currentUser.email}</strong> by dokończyć rejestrację.
    <div class="mt-2">
      <a href="/user/confirmation" class="btn btn-outline-dark btn-sm" data-method="post">Wyślij ponownie email</a>
      <a href="/me/profile/email" class="btn btn-outline-dark btn-sm">Zmień adres email</a>
    </span>
  </div>
</div>\
`;
  }
};

document.addEventListener("fdb.userUpdate", function() {
  window.unconfirmedUser = new UnconfirmedUser(currentUser);
  if (window.isReady) { return window.unconfirmedUser.reload(); }
});

$(document).ready(function() {
  if (window.unconfirmedUser) { return window.unconfirmedUser.reload(); }
});
