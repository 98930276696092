/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
// <select data-contetn-form-updater="true" form-id form-type>
// form-id i form-type moze jakos mozemy sie dowiedzieć łatwiej?
//
// -----------------------------------------------------
const $ = jQuery;

class ContentItemUpdater {
  static initClass() {
  
    this.prototype.url = '/contents/item';
  }
  static setup() {
    return $('#content-form').on('change', '[data-content-item-update]', this.update);
  }

  static update(event) {
    const updater = new ContentItemUpdater(event.currentTarget);
    return updater.update();
  }

  constructor(element) {
    this.element = $(element);
  }

  update() {
    Spinner.show();
    return $.ajax({
      url: this.url,
      type: 'POST',
      dataType: 'script',
      data: this.data(),
      complete() { return Spinner.hide(); }
    });
  }

  choice() {
    return this.element.data("choice");
  }

  data() {
    const data = this.element.parents('form').serializeArray();
    data.push({name: 'item', value: this.element.data("item")});
    if (this.choice()) { data.push({name: 'content[globals][choice]', value: "1"}); }
    return data;
  }
}
ContentItemUpdater.initClass();

$(() => ContentItemUpdater.setup());
