/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const $ = jQuery;

class TwitterOembed {
  static setup() {
    return $("[data-behavior~=twitter-oembed]").each(this.setupOne);
  }

  static setupOne(index, element) {
    const twitterOembed = new TwitterOembed($(element));
    return twitterOembed.oembed();
  }

  constructor(element) {
    this.insert = this.insert.bind(this);
    this.element = element;
  }

  insert(data) {
    return this.element.html(data.html);
  }

  oembed() {
    return $.getJSON(this.url(), this.insert);
  }

  url() {
    return `https://api.twitter.com/1/statuses/oembed.json?id=${this.id()}&align=center&callback=?`;
  }

  id() {
    return this.element.data("id");
  }
}

$(() => TwitterOembed.setup());
