/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const $ = jQuery;

window.UserUpdater = class UserUpdater {
  static update(user) {
    window.currentUser = user;
    return document.dispatchEvent(new Event('fdb.userUpdate'));
  }
};
