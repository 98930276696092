/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const Cls = (window.Dom = class Dom {
  static initClass() {
    this.ajaxComplete = callback => {
      return document.addEventListener("ajax:before", event => event.target.addEventListener("ajax:complete", callback));
    };
  }
});
Cls.initClass();
