/*
 * decaffeinate suggestions:
 * DS002: Fix invalid constructor
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
//= require ./ability_abstract
const $ = jQuery;

window.Ability = class Ability extends AbilityAbstract {
  // abilities for lists
  // -----------------------------------------------------

  manageList() {
    if (this.data("global")) { return false; }
    if (this.hasRole('administrator')) { return true; }
    if (this.isOwner()) { return true; }
    return false;
  }

  // abilities for comments
  // -----------------------------------------------------
  destroyComment() {
    if (this.hasRole('administrator', 'moderator')) { return true; }
    if ($(`#${this.data("comments")}`).children().length > 0) { return false; }
    if (this.isOwner()) { return true; }
    return false;
  }

  updateComment() {
    if (this.hasRole('administrator', 'moderator')) { return true; }
    if (this.isOwner()) { return true; }
    return false;
  }

  updateVote() {
    if (this.isOwner()) { return true; }
    return false;
  }

  // navbar abilities
  viewGuestNavbar() {
    if (!this.isUser()) { return true; }
    return false;
  }

  viewUserNavbar() {
    if (this.isUser()) { return true; }
    return false;
  }

  viewAdminPanel() {
    if (this.hasRole('administrator', 'verifier', 'editor', 'super_editor', 'newsletter_manager',
      'cinema_manager', 'keyword_manager', 'distributor_manager', 'premiere_manager',
      'guide_manager', 'suggest_manager', 'tv_station_manager', 'super_media_manager', 'event_manager',
      'movie_manager', 'quiz_manager', 'cinema_analyst', 'reputation_award_manager', 'source_manager',
      'critic_manager'
    )) { return true; }
    return false;
  }

  viewContentPanel() {
    if (this.hasRole('administrator', 'verifier')) { return true; }
    return false;
  }

  createVideo() {
    if (this.hasRole('administrator', 'media_manager', 'super_media_manager')) { return true; }
    return false;
  }
};


document.addEventListener("turbo:render", () => Ability.reload())
Dom.ajaxComplete(() => Ability.reload());
$(document).ajaxStop(() => Ability.reload());
$(document).ready(() => Ability.reload());
document.addEventListener("fdb.userUpdate", function() {
  Ability.currentUser = window.currentUser;
  return Ability.reload();
});
