/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const $ = jQuery;

window.Comments = class Comments {
  constructor(id) {
    this.id = id;
  }

  updateComments(html, page) {
    if (page === 1) {
      return this.comments().html(html);
    } else {
      return this.comments().append(html);
    }
  }

  updateOrder(html) {
    return this.order().html(html);
  }

  updatePagination(html) {
    return this.pagination().html(html);
  }

  comments() {
    return $(this.selector('comments'));
  }

  order() {
    return $(this.selector('comments-order'));
  }

  pagination() {
    return $(this.selector('comments-pagination'));
  }

  // private methods
  selector(prefix) {
    const selector = [];
    if (prefix) { selector.push(prefix); }
    selector.push("commentable");
    if (this.id) { selector.push(this.id); }
    return `#${selector.join("-")}`;
  }
};
