/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const $ = jQuery;

class NavbarLogin {

  static setup() {
    return $("[data-navbar-login]").each(this.setupOne);
  }

  static setupOne(index, element) {
    return new NavbarLogin(element);
  }

  constructor(form) {
    this.form = $(form);
    this.setRedirect();
  }

  setRedirect() {
    return this.form.find("#redirect").val(window.location.href);
  }
}


$(() => NavbarLogin.setup());
