/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
//= require './inflector'
const $ = jQuery;

window.Age = class Age {
  static setup() {
    return $("[data-age]").each(this.setupOne);
  }

  static setupOne(index, element) {
    return new Age($(element));
  }

  constructor(element) {
    this.element = element;
    this.html(this.calculate());
  }

  html(years) {
    return this.element.html(this.inflect(years));
  }

  parseDate(date) {
    if ((typeof date === "string") && (date !== "")) {
      date = date.split("-");
      return new Date(date[0], parseInt(date[1])-1, (date[2] || 1));
    } else if (typeof date === "number") {
      return new Date(date, 1, 1);
    }
  }

  birthDate() {
    return this.parseDate(this.element.data("birth-date"));
  }

  deathDate() {
    return this.parseDate(this.element.data("death-date")) || new Date;
  }

  inflect(years) {
    return `${years} ${Inflector.get("rok", years)}`;
  }

  calculate() {
    const birthDate = this.birthDate();
    const deathDate = this.deathDate();

    let years = (deathDate.getFullYear() - birthDate.getFullYear());

    if ((deathDate.getMonth() < birthDate.getMonth()) ||
    ((deathDate.getMonth() === birthDate.getMonth()) &&
    (deathDate.getDate() < birthDate.getDate()))) {
      years--;
    }

    return years;
  }
};

$(() => Age.setup());
