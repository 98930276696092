/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS205: Consider reworking code to avoid use of IIFEs
 * DS206: Consider reworking classes to avoid initClass
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const $ = jQuery;

class Query {
  constructor(minLength) {
    this.minLength = minLength;
    this.value = '';
    this.lastValue = '';
    this.emptyValues = [];
  }

  getValue() {
    return this.value;
  }

  setValue(newValue) {
    this.lastValue = this.value;
    return this.value = newValue;
  }

  hasChanged() {
    return !(this.value === this.lastValue);
  }

  markEmpty() {
    return this.emptyValues.push( this.value );
  }

  willHaveResults() {
    return this._isValid() && !this._isEmpty();
  }

  _isValid() {
    return this.value.length >= this.minLength;
  }

  // A value is empty if it starts with any of the values
  // in the emptyValues array.
  _isEmpty() {
    for (let empty of Array.from(this.emptyValues)) {
      if (this.value.slice(0, empty.length) === empty) { return true; }
    }
    return false;
  }
}

class Suggestion {
  constructor(index, term, data, type) {
    this.term = term;
    this.data = data;
    this.type = type;
    this.id = `${index}-soulmate-suggestion`;
    this.index = index;
  }

  select(callback) {
    return callback( this.term, this.data, this.type, this.index, this.id);
  }

  focus() {
    return this.element().addClass( 'focus' );
  }

  blur() {
    return this.element().removeClass( 'focus' );
  }

  render(callback) {
    return `\
<li id="${this.id}" class="soulmate-suggestion">
  ${callback( this.term, this.data, this.type, this.index, this.id)}
</li>\
`;
  }

  element() {
    return $('#' + this.id);
  }
}

class SuggestionCollection {
  constructor(renderCallback, selectCallback) {
    this.renderCallback = renderCallback;
    this.selectCallback = selectCallback;
    this.focusedIndex = -1;
    this.suggestions = [];
  }

  update(results) {
    this.suggestions = [];
    let i = 0;

    return (() => {
      const result1 = [];
      for (var type in results) {
        var typeResults = results[type];
        result1.push((() => {
          const result2 = [];
          for (let result of Array.from(typeResults)) {
            this.suggestions.push( new Suggestion(i, results.term, result.data, type) );
            result2.push(i += 1);
          }
          return result2;
        })());
      }
      return result1;
    })();
  }

  blurAll() {
    this.focusedIndex = -1;
    return Array.from(this.suggestions).map((suggestion) => suggestion.blur());
  }

  render() {
    let h = '';

    if (this.suggestions.length) {

      let type = null;

      for (let suggestion of Array.from(this.suggestions)) {

        if (suggestion.type !== type) {

          if (type !== null) { h += this._renderTypeEnd( type ); }
          ({
            type
          } = suggestion);
          h += this._renderTypeStart();
        }

        h += this._renderSuggestion( suggestion );
      }

      h += this._renderTypeEnd( type );
    }

    return h;
  }

  count() {
    return this.suggestions.length;
  }

  focus(i) {
    if (i < this.count()) {
      this.blurAll();

      if (i < 0) {
        return this.focusedIndex = -1;

      } else {
        this.suggestions[i].focus();
        return this.focusedIndex = i;
      }
    }
  }

  focusElement(element) {
    const index = parseInt( $(element).attr('id') );
    return this.focus( index );
  }

  focusNext() {
    return this.focus( this.focusedIndex + 1 );
  }

  focusPrevious() {
    return this.focus( this.focusedIndex - 1 );
  }

  selectFocused() {
    if (this.focusedIndex >= 0) {
      return this.suggestions[this.focusedIndex].select( this.selectCallback );
    }
  }

  allBlured() {
    return this.focusedIndex === -1;
  }

  // PRIVATE

  _renderTypeStart() {
    return "";
    return `\
<li class="soulmate-type-container">
  <ul class="soulmate-type-suggestions">\
`;
  }

  _renderTypeEnd(type) {
    return "";
    return `\
  </ul>
  <div class="soulmate-type">${type}</div>
</li>\
`;
  }

  _renderSuggestion(suggestion) {
    return suggestion.render( this.renderCallback );
  }
}

var Soulmate = (function() {
  let KEYCODES = undefined;
  Soulmate = class Soulmate {
    static initClass() {
  
      KEYCODES = {9: 'tab', 13: 'enter', 27: 'escape', 38: 'up', 40: 'down'};
    }

    constructor(input, options) {

      this.handleKeydown = this.handleKeydown.bind(this);
      this.handleKeyup = this.handleKeyup.bind(this);
      this.input = input;
      const that = this;

      const {url, types, renderCallback, selectCallback, maxResults, minQueryLength, timeout} = options;


      this.url              = url;
      this.types            = types;
      this.maxResults       = maxResults;
      this.timeout          = timeout || 500;

      this.xhr              = null;

      this.suggestions      = new SuggestionCollection( renderCallback, selectCallback );
      this.query            = new Query( minQueryLength );

      if ($('ul#soulmate').length > 0) {
        this.container = $('ul#soulmate');
      } else {
        this.container = $('<ul id="soulmate" class="dropdown-menu">').insertAfter(this.input);
      }
      this.container.delegate('.soulmate-suggestion', {
        mouseover() { return that.suggestions.focusElement( this ); },
        click(event) {
          event.preventDefault();
          that.suggestions.focusElement( this );
          that.suggestions.selectFocused();

          // Refocus the input field so it remains active after clicking a suggestion.
          return that.input.focus();
        }
      }
      );

      this.input.
        keydown( this.handleKeydown ).
        keyup( this.handleKeyup ).
        mouseover( () => that.suggestions.blurAll());
    }

    handleKeydown(event) {
      let killEvent = true;

      switch (KEYCODES[event.keyCode]) {

        case 'escape':
          this.hideContainer();
          break;

        case 'tab':
          this.suggestions.selectFocused();
          break;

        case 'enter':
          this.suggestions.selectFocused();
          // Submit the form if no input is focused.
          if (this.suggestions.allBlured()) {
            killEvent = false;
          }
          break;

        case 'up':
          this.suggestions.focusPrevious();
          break;

        case 'down':
          this.suggestions.focusNext();
          break;

        default:
          killEvent = false;
      }

      if (killEvent) {
        event.stopImmediatePropagation();
        return event.preventDefault();
      }
    }

    handleKeyup(event) {
      this.query.setValue( this.input.val() );

      if (this.query.hasChanged()) {

        if (this.query.willHaveResults()) {

          this.suggestions.blurAll();
          return this.fetchResults();

        } else {
          return this.hideContainer();
        }
      }
    }

    showOverlay() {
      return $('#soulmate-overlay').show();
    }

    hideOverlay() {
      return $('#soulmate-overlay').hide();
    }

    hideContainer() {
      this.suggestions.blurAll();

      this.container.hide();
      this.hideOverlay();

      // Stop capturing any document click events.
      return $(document).unbind('click.soulmate');
    }

    showContainer() {
      this.container.show();
      this.showOverlay();

      // Hide the container if the user clicks outside of it.
      $(document).bind('click.soulmate', event => {
        if (!this.container.has( $(event.target) ).length) { return this.hideContainer(); }
      });

      return Age.setup();
    }

    fetchResults() {
      // Cancel any previous requests if there are any.
      if (this.xhr != null) { this.xhr.abort(); }

      return this.xhr = $.ajax({
        url: this.url,
        dataType: 'json',
        timeout: this.timeout,
        cache: true,
        data: {
          term: this.query.getValue(),
          types: this.types,
          limit: this.maxResults
        },
        success: data => {
          return this.update( data.results );
        }
      });
    }

    update(results) {
      this.suggestions.update(results);

      if (this.suggestions.count() > 0) {
        this.container.html( $(this.suggestions.render()) );
        return this.showContainer();

      } else {
        this.query.markEmpty();
        return this.hideContainer();
      }
    }
  };
  Soulmate.initClass();
  return Soulmate;
})();

$.fn.soulmate = function(options) {
  new Soulmate($(this), options);
  return $(this);
};

window._test = {
  Query,
  Suggestion,
  SuggestionCollection,
  Soulmate
};
