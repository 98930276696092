import { library, icon } from '@fortawesome/fontawesome-svg-core'


// brands
import brands from './brands'
brands.forEach(library.add, library)

// light
import light from './light'
light.forEach(library.add, library)

// solid
import solid from './solid'
solid.forEach(library.add, library)

// custom
import custom from './custom'
custom.forEach(library.add, library)


// only for testing full icons-set
/*
import { fal } from '@fortawesome/pro-light-svg-icons'
Object.values(fal).forEach(library.add, library)

import { fas } from '@fortawesome/free-solid-svg-icons'
Object.values(fas).forEach(library.add, library)

import { fad } from '@fortawesome/pro-duotone-svg-icons'
Object.values(fas).forEach(library.add, library)
*/

export default { icon: icon }
