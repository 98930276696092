import { Controller } from "@hotwired/stimulus"
import { Popover } from "bootstrap"

export default class extends Controller {
  static values = {
    url: String,
    content: String,
    trigger: { type: String, default: "manual" },
  }

  connect() {
    this.popover = new Popover(this.element, this.options)
  }

  toggle() {
    if(this.shouldFetchContent) {
      this.fetchContent().then(()=> this.togglePopover())
    } else {
      this.togglePopover()
    }
  }

  togglePopover() {
    this.popover.toggle()
  }

  fetchContent() {
    return fetch(this.urlValue)
      .then(response => response.text())
      .then(text => this.setContentAndToogle(text))
  }

  setContentAndToogle(text) {
    this.contentValue = text
  }

  get shouldFetchContent() {
    return this.urlValue && !this.contentValue
  }

  get options() {
    return {
      content: this.content.bind(this),
      trigger: this.triggerValue,
      html: true
    }
  }

  content() {
    return this.contentValue
  }
}
