/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
//assign value to input element based on selected radio element

const $ = jQuery;

class ValueAssigner {
  static observe() {
    return $(document).on("click", "[data-value-assign]", event => new ValueAssigner(event));
  }

  constructor(event) {
    this.element = $(event.currentTarget);
    this.assign();
  }

  target() {
    return $(this.element.data("target"));
  }

  assign() {
    return this.target().val(this.element.val());
  }
}

ValueAssigner.observe();

