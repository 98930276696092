/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const $ = jQuery;

class EditorToolbar {
  constructor() {
    this.onClick = this.onClick.bind(this);
    this.addObservers();
  }

  addObservers() {
    return $(document).on("click", "[data-toolbar] [data-action]", this.onClick);
  }

  onClick(event) {
    event.preventDefault();
    const action = new EditorToolbarAction($(event.currentTarget));
    return action.handle();
  }
}


class EditorToolbarAction {
  constructor(button) {
    this.button = button;
  }

  toolbar() {
    return this.button.parents("[data-toolbar]");
  }

  textarea() {
    return new Textarea($(this.toolbar().data("target")));
  }

  handle() {
    return this[this._action()]();
  }

  bold() {
    return this._simpleInsert("*", "*");
  }

  italic() {
    return this._simpleInsert("_", "_");
  }

  h1() {
    return this._blockInsert("h1. ");
  }

  h2() {
    return this._blockInsert("h2. ");
  }

  h3() {
    return this._blockInsert("h3. ");
  }

  center() {
    return this._blockInsert("p=. ");
  }

  quote() {
    return this._blockInsert("bq. ");
  }

  ul() {
    return this._blockInsert("* ");
  }

  ol() {
    return this._blockInsert("# ");
  }

  link() {
    return this._simpleInsert('"', '":https://');
  }

  strikethrough() {
    return this._simpleInsert("-", "-");
  }

  table() {
    return this._insert(this.tableMarkup());
  }

  tableMarkup() {
    return `\
|_. {{selection}}{{cursor}} |_.  |
|    |    |\
`;
  }

  page() {
    return this._insert("\n\n-------------- page break --------------\n\n");
  }

  youtube() {
    return this._simpleInsert('{{youtube url="', '"}}');
  }

  facebook() {
    return this._simpleInsert('{{facebook url="', '"}}');
  }

  twitter() {
    return this._simpleInsert('{{twitter url="', '"}}');
  }

  instagram() {
    return this._simpleInsert('{{instagram url="', '"}}');
  }

  vimeo() {
    return this._simpleInsert('{{vimeo url="', '"}}');
  }

  iframe() {
    return this._simpleInsert('{{iframe url="', '"}}');
  }

  gallery() {
    return this._simpleInsert('{{gallery}}"', '{{/gallery}}');
  }


  _action() {
    return this.button.data("action");
  }

  _simpleInsert(start, end) {
    const text = [start, "{{selection}}{{cursor}}"];
    if (end) { text.push(end); }
    return this._insert(text.join(""));
  }

  _insert(text) {
    return this.textarea().insert(text);
  }

  _blockInsert(text) {
    this.textarea().moveCoursorToBeginingOfLine();
    this._insert(`${text}{{selection}}`);
    return this.textarea().moveCoursorToEndOfLine();
  }
}


new EditorToolbar();
