/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const $ = jQuery;

const Cls = (window.ContentFileUploader = class ContentFileUploader {
  static initClass() {
  
    this.prototype.url = '/contents/upload.js';
  }
  static setup() {
    this.attach();

    $(document).ajaxComplete(() => {
      return this.attach();
    });
    return Dom.ajaxComplete(() => this.attach());
  }

  static attach() {
    return $("[data-content-file-upload]").each(function(index, element) {
      if (!$(element).data("fileupload")) { return new ContentFileUploader(element); }
    });
  }

  constructor(element) {
    this.submit = this.submit.bind(this);
    this.start = this.start.bind(this);
    this.stop = this.stop.bind(this);
    this.element = $(element);
    this.fileUpload();
  }

  submit(event, data) {
    data.formData =  data.form.serializeArray();
    data.formData.push({name: "form_id", value: this.formId()});
    data.formData.push({name: "form_type", value: this.element.data("form-type")});

    // for multiple upload we are adding each next image to new field
    if (this.element.attr("multiple")) {
      if (this.exceededLimit()) { return false; }
      return this.incrementFormId();
    }
  }

  start(event) {
    return Spinner.show();
  }

  stop(event) {
    return Spinner.hide();
  }

  exceededLimit() {
    return this.max() < this.extractNumberFromFormId();
  }

  max() {
    return parseInt(this.element.data("max"));
  }

  formId() {
    return this.element.attr("data-form-id");
  }

  extractNumberFromFormId() {
    return parseInt(this.formId().match(/\d+/)[0]);
  }

  nextFormId() {
    return this.formId().replace(this.extractNumberFromFormId(), this.extractNumberFromFormId() + 1);
  }

  incrementFormId() {
    return this.element.attr("data-form-id", this.nextFormId());
  }

  fileUpload() {
    return this.element.fileupload({
      dataType: 'script',
      url: this.url,
      submit: this.submit,
      start: this.start,
      stop: this.stop
    });
  }
});
Cls.initClass();

$(() => ContentFileUploader.setup());
