/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const $ = jQuery;

const Cls = (window.FacebookSDK = class FacebookSDK {
  constructor() {
    this.initializeSDK = this.initializeSDK.bind(this);
  }

  static initClass() {
    this.prototype.loading = false;
  }

  static load() {
    return new Promise((resolve, reject) => {
      if (window["FB"]) {
        resolve();
      } else {
        window.addEventListener("facebook.ready", resolve);
      }

      if (!this.loading) {
        const sdk = new FacebookSDK();
        sdk.load();
        return this.loading = true;
      }
    });
  }

  initializeSDK() {
    FB.init({
      appId      : this.appId(),
      xfbml      : true,
      version    : 'v2.6'
    });

    window.dispatchEvent(new CustomEvent("facebook.ready", {bubbles: true}));

    return FB.Event.subscribe('edge.create', targetUrl => ga('send', 'event', 'FacebookLike', 'Yes', {'nonInteraction': 1}));
  }


  load() {
    window.fbAsyncInit = this.initializeSDK;
    const js = document.createElement('script');
    js.src = "//connect.facebook.net/pl_PL/all.js";
    return document.body.appendChild(js);
  }

  appId() {
    return $("meta[property=fb\\:app_id]").attr("content");
  }
});
Cls.initClass();


$(() => FacebookSDK.load());
