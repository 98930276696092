/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const $ = jQuery;

class TextileEditorLoader {
  constructor() {
    $("[data-textile]").each((i, element) => this.load(element));
  }

  load(element) {
    const mode = $(element).data("textile");
    const id = $(element).attr("id");

    return TextileEditor.initialize(id, mode);
  }
}

$(() => new TextileEditorLoader);
