// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "@hotwired/stimulus"
import icons from "./../icons"

export default class extends Controller {
  static get prefixes() {
    return {
      'light': 'fal',
      'solid': 'fas',
      'brands': 'fab',
      'duotone': 'fad',
      'custom': 'fac'
    }
  }

  connect() {
    this.render()
  }

  render() {
    if (this.icon) {
      this.element.innerHTML = this.icon.html[0]
    }
  }

  get prefixes() {
    return this.constructor.prefixes
  }

  get prefix() {
    return this.prefixes[this.type] || this.type
  }

  get icon() {
    let icon = icons.icon({ prefix: this.prefix, iconName: this.name })

    if(icon) {
      return icon
    } else {
      ga('send', 'event', 'MissingIcon', this.name, { 'nonInteraction': 1})
      console.error(`Icon ${this.type} ${this.name} don't exists`)
    }
  }

  get type() {
    return this.element.getAttribute('type') || "light"
  }

  get name() {
    return this.element.getAttribute("name")
  }
}
