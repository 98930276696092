/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
class Inflector {
  static initClass() {
  
    this.prototype.definitions =
      {"rok": ["lata", "lat"]};
  }


  get(singular, count) {
    const plural = this.definitions[singular];
    if (!plural) { throw "Unknow word"; }

    if (count === 1) { return singular; }

    if (count > 19) { count = count % 10; }

    if ((count >= 2) && (count <= 4)) {
      return plural[0];
    } else {
      return plural[1];
    }
  }
}
Inflector.initClass();

window.Inflector = new Inflector;
