/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const $ = jQuery;

window.ContentMove = class ContentMove {
  static setup() {
    return $("[data-content-move]").each(this.setupOne);
  }

  static setupOne(index, element) {
    return new ContentMove($(element));
  }

  constructor(element) {
    this.element = element;
    this.move();
  }

  move() {
    this.target().append(this.element.html());
    this.element.empty();
  }

  target() {
    return $(this.element.data("content-move"));
  }
};


$(() => ContentMove.setup());
