import { Controller } from "@hotwired/stimulus"
import { useIntersection } from "stimulus-use";

export default class extends Controller {
  static urlsVisibility = {}

  connect() {
    useIntersection(this, this.options)
  }

  disappear() {
    this.urlsVisibility[this.url] = 0
    this.updateUrl()
  }

  appear(entity) {
    this.urlsVisibility[this.url] = entity.intersectionRatio
    this.updateUrl()
  }

  updateUrl() {
    const url = this.mostVisibleUrl()
    if (window.location.href != url) {
      debug("Updating url", window.location.href, url, this.urlsVisibility)
      window.history.replaceState(null, null, url)
    }
  }

  mostVisibleUrl() {
    return Object.keys(this.urlsVisibility).reduce((a, b) => this.urlsVisibility[a] > this.urlsVisibility[b] ? a : b)
  }

  get options() {
    return { rootMargin: this.margin }
  }

  get urlsVisibility() {
    return this.constructor.urlsVisibility
  }

  get url() {
    return this.data.get("url")
  }

  get margin() {
    return this.data.get("margin") || "0px"
  }
}
