/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const $ = jQuery;

window.ContentSubmit = class ContentSubmit {

  submit() {
    return $(".content-form-submit");
  }

  update(html) {
    return this.submit().html(html);
  }
};

