/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const $ = jQuery;

const Cls = (window.Vote = class Vote {
  static initClass() {
    this.votes = null;
  
    this.refreshOne = (index, element) => {
      const vote = new Vote(element);
      return vote.refresh();
    };
  }

  static set(kind, id, value)  {
    if (this.votes[kind] == null) { this.votes[kind] = {}; }
    this.votes[kind][id] = value;
    return this.refresh();
  }

  static get(kind, id) {
    if (this.votes[kind]) { return this.votes[kind][id]; }
  }

  static remove(kind, id) {
    if (this.votes[kind]) { delete this.votes[kind][id]; }
    return this.refresh();
  }

  static has(kind, id) {
    return this.votes[kind] && this.votes[kind][id];
  }

  static refresh() {
    if (this.votes) { return $("[data-behavior~=vote]").each(this.refreshOne); }
  }

  constructor(element) {
    this.element = $(element);
  }

  refresh() {
    if (this.hasChanged) {
      this.clearVote();
      if (this.hasVote()) { return this.insertVote(); }
    }
  }

  insertVote() {
    this.element.find(`[data-vote-selected=${this.vote()}]`).addClass("selected");
    this.element.find(".vote-value").html(this.vote());
    this.element.data("vote-value", this.vote());
    return this.element.addClass("vote-yes");
  }

  clearVote() {
    this.element.find("[data-vote-selected]").removeClass("selected");
    this.element.find(".vote-value").empty();
    this.element.removeData("vote-value");
    return this.element.removeClass("vote-yes");
  }

  hasChanged() {
    return this.element.data("vote-value") !== this.vote();
  }

  id() {
    return this.element.data("id").split(/-/)[1];
  }

  kind() {
    return this.element.data("id").split(/-/)[0];
  }

  vote() {
    return Vote.get(this.kind(), this.id());
  }

  hasVote() {
    return Vote.has(this.kind(), this.id());
  }
});
Cls.initClass();

$(() => Vote.refresh());

document.addEventListener("fdb.userUpdate", function() {
  Vote.votes = new VoteParser(currentUser.votes).parse();
  return Vote.refresh();
});
