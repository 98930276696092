/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const $ = jQuery;

class Lightbox2MoviePictureJsonFormatter extends Lightbox2JsonFormatter {

  description() {
    const description = super.description(...arguments);
    description.push(this.movie(this.item.movie));
    description.push(this.people());
    return description;
  }

  people() {
    if (!this.item.people) { return ""; }

    let people = [];

    $.each(this.item.people, (index, person) => {
      return people.push(this.person(person));
    });

    people = people.join(", ");
    if (people) { return `${this.label("Osoby")} ${people}`; }
  }

  person(person) {
    return `\
<a target="_blank" href="${person.url}">${person.name.name}</a>\
`;
  }

  movie(movie) {
    return `\
${this.label("Film")} <a target="_blank" href="${movie.url}">${movie.title.name} (${movie.year})</a>\
`;
  }
}


window.Lightbox2MoviePictureJsonFormatter = Lightbox2MoviePictureJsonFormatter;
