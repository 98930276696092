/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const $ = jQuery;

const Cls = (window.VoteWidgetVote = class VoteWidgetVote {
  static initClass() {
  
    this.attachOne = (index, element) => {
      const voteWidgetVote = new VoteWidgetVote(element);
      return voteWidgetVote.render();
    };
  }

  static attach() {
    return $("[data-behavior~=vote-widget-vote]").each(this.attachOne);
  }

  constructor(element) {
    this.updateHoverValue = this.updateHoverValue.bind(this);
    this.resetHoverValue = this.resetHoverValue.bind(this);
    this.element = $(element);
    this.observeHover();
  }

  observeHover() {
    this.element.on("mouseenter", ".vote-star", this.updateHoverValue);
    return this.element.on("mouseleave", ".vote-star", this.resetHoverValue);
  }

  updateHoverValue(event) {
    const value = $(event.currentTarget).data("vote-selected");
    this.element.find(`[data-vote-selected=${value}]`).addClass("hover");
    this.element.find("[data-vote-selected]").addClass("hover-state");
    return this.element.find(".vote-value").html(value);
  }

  resetHoverValue(event) {
    this.element.find(".vote-value").html(this.voteValue());
    return this.element.find("[data-vote-selected]").removeClass("hover hover-state");
  }

  voteValue() {
    return this.element.find("[data-behavior~=vote]").data("vote-value") || "";
  }

  id() {
    return this.element.data("id");
  }

  url() {
    return this.element.data("url");
  }

  createUrl(value) {
    return `${this.url()}?vote[value]=${value}`;
  }

  deleteUrl() {
    return this.url();
  }

  render() {
    this.element.html(this.template());
    return Vote.refresh();
  }

  template() {
    return `\
<span class="vote" data-behavior="vote" data-id="${this.id()}">
  <div class="vote-current">
    Twoja ocena:
      <strong class="vote-value"></strong>

    <a href="${this.deleteUrl()}" data-remote="true"
      data-method="delete" class="vote-remove"><i data-controller="icon" name="times"></i></a>
  </div>
  <div class="vote-stars">
    <a href="${this.createUrl(10)}" class="vote-star" data-vote-selected="10" data-remote="true" data-method="post"><i data-controller="icon" name="star"></i></a>
    <a href="${this.createUrl(9)}" class="vote-star" data-vote-selected="9" data-remote="true" data-method="post"><i data-controller="icon" name="star"></i></a>
    <a href="${this.createUrl(8)}" class="vote-star" data-vote-selected="8" data-remote="true" data-method="post"><i data-controller="icon" name="star"></i></a>
    <a href="${this.createUrl(7)}" class="vote-star" data-vote-selected="7" data-remote="true" data-method="post"><i data-controller="icon" name="star"></i></a>
    <a href="${this.createUrl(6)}" class="vote-star" data-vote-selected="6" data-remote="true" data-method="post"><i data-controller="icon" name="star"></i></a>
    <a href="${this.createUrl(5)}" class="vote-star" data-vote-selected="5" data-remote="true" data-method="post"><i data-controller="icon" name="star"></i></a>
    <a href="${this.createUrl(4)}" class="vote-star" data-vote-selected="4" data-remote="true" data-method="post"><i data-controller="icon" name="star"></i></a>
    <a href="${this.createUrl(3)}" class="vote-star" data-vote-selected="3" data-remote="true" data-method="post"><i data-controller="icon" name="star"></i></a>
    <a href="${this.createUrl(2)}" class="vote-star" data-vote-selected="2" data-remote="true" data-method="post"><i data-controller="icon" name="star"></i></a>
    <a href="${this.createUrl(1)}" class="vote-star" data-vote-selected="1" data-remote="true" data-method="post"><i data-controller="icon" name="star"></i></a>
  </div>
  <div class="vote-remove pull-right float-right" style="display: none;"></div>
</span>\
`;
  }
});
Cls.initClass();


$(() => VoteWidgetVote.attach());

