/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
//= require './adults'
const $ = jQuery;

class Adsense {
  load() {
    if (this.enabled()) {
      return this.loadAds();
    } else {
      return this.hideAds();
    }
  }

  enabled() {
    return !Adults.status();
  }

  hideAds() {
    return $("html").addClass("adsbygoogle-disabled");
  }

  loadAds() {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src = '//pagead2.googlesyndication.com/pagead/js/adsbygoogle.js';
    const other = document.getElementsByTagName('script')[0];
    return other.parentNode.insertBefore(script, other);
  }

  retry() {
    return $(".adsbygoogle").each(function(index, element) {
      element = $(element);
      if (element.is(':empty') && (element.data("adsbygoogle-status") === "done")) {
        element.removeAttr("data-adsbygoogle-status");
        return (window.adsbygoogle = window.adsbygoogle || []).push({});
      }
    });
  }
}

window.adsense = new Adsense();
$(() => adsense.load());
$(window).on("load", () => adsense.retry());
