/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const $ = jQuery;

window.VoteWidgetWatch = class VoteWidgetWatch {
  constructor(element) {
    this.element = $(element);
  }

  render() {
    this.element.html(this.template());
    return ListItem.refresh();
  }

  id() {
    return this.element.data("id");
  }

  url() {
    return this.element.data("url");
  }

  template() {
    return `\
<div class="list" data-behavior="list-item" data-id="${this.id()}" data-list="watch">
  <a class="btn btn-block w-100 btn-default btn-outline-secondary"  data-remote="true" rel="nofollow" data-method="post" href="${this.url()}">
    <i data-controller="icon" name="eye" class="list-yes-visible text-primary"></i>
    <i data-controller="icon" name="eye" class="list-yes-hidden"></i>
    Chcę obejrzeć
  </a>
</div>\
`;
  }
};
