/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const $ = jQuery;

class ItemsTruncator {
  static setup() {
    return $("[data-items-truncator]").each((index, element) => new ItemsTruncator(element));
  }

  constructor(element) {
    this.moreClicked = this.moreClicked.bind(this);
    this.element = $(element);
    this.truncate();
    this.observeMoreClick();
  }

  target() {
    return this.element.data("target");
  }

  targetElements() {
    return this.element.find(this.target());
  }

  limit() {
    return this.element.data("limit");
  }

  more() {
    return this.element.data("more");
  }

  moreElement() {
    return this.element.find(this.more());
  }

  observeMoreClick() {
    return this.moreElement().on("click", this.moreClicked);
  }

  moreClicked(event) {
    event.preventDefault();
    this.moreElement().hide();
    return this.targetElements().show();
  }

  truncate() {
    this.moreElement().hide();
    return this.targetElements().each((index, element) => {
      if (index >= this.limit()) {
        this.moreElement().show();
        return $(element).hide();
      }
    });
  }
}

$(() => ItemsTruncator.setup());

