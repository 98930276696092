/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const $ = jQuery;

class PremieresPage {
  constructor() {
    this.redirect = this.redirect.bind(this);
    this.onDateChange();
  }

  onDateChange() {
    return $('.premieres-page .date').on('changeDate', this.redirect);
  }

  url() {
    let url = `/premiery/${this.year()}/${this.month()}`;

    if (this.kind()) {
      url += `?kind=${this.kind()}`;
    }

    return url;
  }

  year() {
    return document.querySelector("#year").value;
  }

  month() {
    return document.querySelector("#month").value;
  }

  kind() {
    return document.querySelector("#kind").value;
  }

  redirect(event) {
    return window.location = this.url();
  }
}

$(() => window.pp = new PremieresPage());
