/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const $ = jQuery;

class Lightbox2Formatter {

  constructor(index, item) {
    this.index = index;
    this.item = item;
  }

  imageUrl() {
    throw "Please implement this method";
  }

  description() {
    throw "Please implement this method";
  }

  thumbnailUrl() {
    throw "Please implement this method";
  }


  formattedDescription() {
    let description = this.description();
    if (typeof description === "string") { description = [description]; }

    const formattedDescription = [];

    $.each(description, (index, value) => {
      if (value) { return formattedDescription.push(this.content_tag("li", value)); }
    });
    if (formattedDescription.length > 0) {
      return this.content_tag("ul", formattedDescription.join(""));
    } else {
      return "";
    }
  }

  content_tag(name, content) {
    return `<${name}>${content}</${name}>`;
  }

  label(text) {
    return this.content_tag("strong", `${text}:`);
  }
}

window.Lightbox2Formatter = Lightbox2Formatter;


