/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const $ = jQuery;

const Cls = (window.UserBinding = class UserBinding {
  static initClass() {
    this.currentUser = null;
  }
  static reload(user) {
    if (this.currentUser) {
      return $("[data-user]").each((index, element) => {
        return new UserBinding($(element), this.currentUser);
      });
    }
  }

  constructor(element, user) {
    this.element = $(element);
    this.user  = user;
    this.bind();
  }

  attribute() {
    return this.user[this.element.data('user')];
  }

  bind() {
    if (this.attribute() !== null) {
      if (this.element.is('img')) {
        return this.element.attr('src', this.attribute());
      } else if (this.element.is('a')) {
        return this.element.attr('href', this.attribute());
      } else {
        return this.element.html(this.attribute());
      }
    }
  }
});
Cls.initClass();


document.addEventListener("turbo:render", () => UserBinding.reload())
Dom.ajaxComplete(() => UserBinding.reload());
$(document).ajaxStop(() => UserBinding.reload());
$(document).ready(() => UserBinding.reload());
document.addEventListener("fdb.userUpdate", function() {
  UserBinding.currentUser =  window.currentUser;
  return UserBinding.reload();
});
