/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const $ = jQuery;

class Csrf {
  static refresh() {
    return $('input[name=authenticity_token]').val($('meta[name=csrf-token]').attr('content'));
  }
}

// jquery-rails do this only on dom-ready, but we also want when ajax is called
$(() => Csrf.refresh());
$(document).ajaxStop(() => Csrf.refresh());
Dom.ajaxComplete(Csrf.refresh);
