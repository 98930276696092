/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const $ = jQuery;

class TextTruncate2 {
  static initClass() {
    this.setup = () => {
      return $("[data-truncate2]").each(this.setupOne);
    };
  }

  static setupOne(index, element) {
    return new TextTruncate2(element);
  }

  constructor(element) {
    this.expand = this.expand.bind(this);
    this.element = $(element);
    this.truncate();
  }


  lines() {
    return parseInt(this.element.data("lines"));
  }

  currentHeight() {
    return this.element.height();
  }

  moreHeight() {
    return 30;
  }

  maxHeight() {
    return this.minHeightForTruncate() - this.moreHeight();
  }

  minHeightForTruncate() {
    return this.lineHeight() * this.lines();
  }

  lineHeight() {
    return parseInt(this.element.css("line-height"));
  }

  truncate() {
    // do not hide only one line
    if (this.currentHeight() > this.minHeightForTruncate()) {
      this.element.css({height: this.maxHeight(), overflow: "hidden"});
      this.element.after(this.more());
      return this.element.next().on("click", this.expand);
    }
  }

  more() {
    return `\
<a href="#" class="fw-bold caret caret-b small text-muted">Zobacz więcej</a>\
`;
  }

  expand(event) {
    event.preventDefault();
    this.element.next().remove();
    return this.element.css({height: "auto"});
  }

  attach() {
    return $(this.element).truncate(this.options());
  }
}
TextTruncate2.initClass();

$(TextTruncate2.setup);
window.addEventListener("load", TextTruncate2.setup);
$(document).ajaxStop(TextTruncate2.setup);
Dom.ajaxComplete(TextTruncate2.setup);
