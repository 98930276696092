/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const $ = jQuery;
class ContentPreview {
  static initClass() {
  
    this.prototype.url = '/contents/preview';
    this.prototype.contentCache = null;
  }
  static setup() {
    return $('#content-form').on('click', '[data-content-preview]', this.preview);
  }

  static preview(event) {
    event.preventDefault();
    const preview = new ContentPreview(event.currentTarget);
    return preview.preview();
  }

  constructor(element) {
    this.preview = this.preview.bind(this);
    this.element = $(element);
  }

  content() {
    return $(this.element.data("content")).val();
  }

  target() {
    return this.element.data("target");
  }

  paragraphs() {
    return this.element.data("paragraphs");
  }

  preview() {
    return this.callRemote();
  }

  callRemote() {
    return $.ajax({
      url: this.url,
      type: 'POST',
      dataType: 'script',
      data: this.data()
    });
  }

  changed() {
    if (this.contentCache === this.content()) { return false; }
    this.contentCache = this.content();
    return true;
  }

  data() {
    const data = [];
    data.push({name: 'content', value: this.content()});
    data.push({name: 'paragraphs', value: this.paragraphs()});
    data.push({name: 'target', value: this.target()});
    return data;
  }
}
ContentPreview.initClass();

$(() => ContentPreview.setup());
