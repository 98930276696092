/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const $ = jQuery;

class TextTruncate {
  static initClass() {
    this.setup = () => {
      return $("[data-truncate]:not(.truncated)").each(this.setupOne);
    };
  
    this.setupOne = (index, element) => {
      return this.instance($(element)).truncate();
    };
  }

  static instance(element) {
    if (!element.data('truncate-instance')) { element.data('truncate-instance', new TextTruncate(element)); }
    return element.data('truncate-instance');
  }

  constructor(element) {
    this.element = $(element);
  }

  options() {
    return {
      max_length: this.maxLength(),
      ellipsis: '…',
      more: 'zobacz więcej',
      less: 'zobacz mniej',
      css_more_class: 'truncator-link truncator-more more more-down more-b',
      css_less_class: 'truncator-link truncator-less more more-up more-t',
      link_prefix: ' ',
      link_suffix: ' '
    };
  }


  maxLength() {
    return this.element.data("length") || 500;
  }

  truncate() {
    this.element.addClass("truncated");
    return this.element.truncate(this.options());
  }
}
TextTruncate.initClass();

$(() => TextTruncate.setup());
$(document).ajaxStop(TextTruncate.setup);
Dom.ajaxComplete(TextTruncate.setup);
