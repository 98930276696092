/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
// <select data-contetn-form-updater="true" form-id form-type>
// form-id i form-type moze jakos mozemy sie dowiedzieć łatwiej?
//
// -----------------------------------------------------
const $ = jQuery;


class ContentFormUpdater {
  static initClass() {
  
    this.prototype.url = '/contents/form';
  }
  static setup() {
    $('#content-form').on('change', '[data-content-form-update]', this.update);
    return $('#content-form').on('click', 'a[data-content-form-update]', this.update);
  }

  static update(event) {
    event.preventDefault();
    const updater = new ContentFormUpdater(event.currentTarget);
    return updater.update();
  }

  constructor(element) {
    this.element = $(element);
  }

  update() {
    Spinner.show();
    return $.ajax({
      url: this.url,
      type: 'POST',
      dataType: 'script',
      data: this.data(),
      complete() { return Spinner.hide(); }
    });
  }

  form() {
    return this.element.parents("tr[data-id]");
  }

  data() {
    const data = this.element.parents('form').serializeArray();
    data.push({name: 'form_id', value: this.form().data('id')});
    data.push({name: 'form_type', value: this.form().data('name')});
    return data.filter(item => item.name !== "_method");
  }
}
ContentFormUpdater.initClass();

$(() => ContentFormUpdater.setup());
