/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const $ = jQuery;

const Cls = (window.TextTruncate3 = class TextTruncate3 {
  static initClass() {
    this.timer = null;
    this.attachOnResize = () => {
      clearTimeout(this.timer);
      return this.timer = setTimeout(this.attach, 500);
    };
  
    this.attach = () => {
      return $("[data-behavior~=truncate3]").each(this.attachOne);
    };
  
    this.attachOne = (index, element) => {
      return this.instance($(element)).truncate();
    };
  }

  static instance(element) {
    if (!element.data('truncate3')) { element.data('truncate3', new TextTruncate3(element)); }
    return element.data('truncate3');
  }

  constructor(element) {
    this.element = $(element);
  }

  lines() {
    return parseInt(this.element.data("lines"));
  }

  currentHeight() {
    return this.element.height();
  }

  maxHeight() {
    return this.lineHeight() * this.lines();
  }

  lineHeight() {
    return parseInt(this.element.css("line-height"));
  }

  truncate() {
    return $(this.element).shave(this.maxHeight());
  }
});
Cls.initClass();

$(() => TextTruncate3.attach());
window.addEventListener("load", TextTruncate3.attach);
$(document).ajaxStop(TextTruncate3.attach);
$(window).resize(TextTruncate3.attachOnResize);
Dom.ajaxComplete(TextTruncate3.attach);

