/*
 * <select
 *   data-controller="form-submit"
 *   data-action: "form-submit#submit",
 *   data-form-submit-action-value: "/admin/stream_platform",
 *   data-form-submit-method-value: "post"">
 */

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    action: String,
    method: String,
    form: String,
    name: { type: String, default: "form_submit" },
  }

  submit(event) {
    event.preventDefault();
    let submitter = this.submitter
    this.form.appendChild(submitter)
    submitter.click()
    this.form.removeChild(submitter)
  }

  get submitter() {
    let submitter = document.createElement("input")
    submitter.type = "submit"
    submitter.name = this.nameValue
    submitter.value = true
    submitter.hidden = true

    if (this.actionValue) submitter.formAction = this.actionValue
    if (this.methodValue) submitter.formMethod = this.methodValue

    return submitter
  }

  get form() {
    if (this.formValue) {
      return document.querySelector(this.formValue)
    } else {
      return this.element.form
    }
  }
}
