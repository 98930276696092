/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const $ = jQuery;

window.ContentForm = class ContentForm {

  constructor(name) {
    this.name = name;
  }

  form() {
    return $(`.${this.name}`);
  }

  update(html) {
    this.form().addClass("remove");
    this.form().first().before(html);
    this.form().filter(".remove").remove();
  }
};


