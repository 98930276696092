/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const $ = jQuery;

class BackgroundImage {

  constructor() {
    this.setBackground = this.setBackground.bind(this);
    $('[data-background]').each(this.setBackground);
  }

  setBackground(index, element) {
    element = $(element);
    const background = element.data('background');
    return element.css('background-image', `url(${background})`);
  }
}

$(() => new BackgroundImage);

