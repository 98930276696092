import { Controller } from "@hotwired/stimulus"
import { Tooltip } from "bootstrap"

export default class extends Controller {
  static targets = [
    "content"
  ]
  static values = {
    message: { 
      type: String,
      default: "Copied!"
    },
    duration: {
      type: Number,
      default: 1000,
    },
  }

  copy(event) {
    event.preventDefault()

    let currentTarget = event.currentTarget
    navigator.clipboard.writeText(this.content).then(() => this.copied(currentTarget))
  }

  get content() {
    return (this.contentTarget.value || this.contentTarget.innerText).trim()
  }

  copied(target) {
    if (this.timeout) {
      clearTimeout(this.timeout)
    }

    let tooltip = new Tooltip(target, {trigger: "manual", title: this.messageValue})
    tooltip.show()

    this.timeout = setTimeout(() => {
      tooltip.dispose()
    }, this.durationValue)
  }
}
