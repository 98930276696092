/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const $ = jQuery;

class RadioButtons {

  constructor() {
    this.onButtonClick = this.onButtonClick.bind(this);
    this.observeButtonClick();
  }


  observeButtonClick() {
    return $(document).on('click', '[data-radio-buttons]', this.onButtonClick);
  }

  onButtonClick(event) {
    event.preventDefault();
    const group = $(event.currentTarget);
    const button = $(event.target);

    if (!this.isButton(button)) { return; }
    if (this.isInactive(button)) { return; }

    const isActive = this.isActive(button);
    this.deactiveGroup(group);
    if (!isActive) { this.active(button); }
    return this.triggerChange(button);
  }

  triggerChange(button) {
    return button.find('input').trigger("change");
  }

  deactiveGroup(group) {
    group.find(".btn").removeClass("active");
    return group.find("input").prop('checked', false);
  }

  isActive(button) {
    return button.hasClass('active');
  }

  isButton(button) {
    return button.hasClass('btn');
  }

  isInactive(button) {
    return button.hasClass('inactive');
  }

  active(button) {
    button.addClass('active');
    return button.find('input').prop('checked', true);
  }
}

$(() => new RadioButtons());
