/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const $ = jQuery;

window.GuideModal = class GuideModal {

  constructor(content, selector) {
    this.content = content;
    this.selector = selector;
    this.modal = this.initializeModal();
    this.updateContent();
  }


  toggle() {
    return this.modal.toggle();
  }

  initializeModal() {
     return new bootstrap.Modal(this.container(), {show: false, animation: false});
   }

  updateContent() {
    this.modalTitle().empty();
    this.modalTitle().text(this.contentHeader().text());

    this.modalBody().empty();
    return this.modalBody().append(this.contentBody());
  }

  modalBody() {
    return $('.guide-modal-body');
  }

  container() {
    return document.querySelector('.guide-modal');
  }

  modalTitle() {
    return $('.guide-modal-title');
  }

  contentHeader() {
    return $(this.content).filter(this.selector);
  }

  contentBody() {
    let body;
    return body = this.contentHeader().nextUntil(this.nodeName());
  }

  nodeName() {
    switch (false) {
      case !this.contentHeader().is("h5"): return "h5, h4, h3, h2, h1, .header-responsive";
      case !this.contentHeader().is("h4"): return "h4, h3, h2, h1, .header-responsive";
      case !this.contentHeader().is("h3"): return "h3, h2, h1, .header-responsive";
      case !this.contentHeader().is("h2"): return "h2, h1, .header-responsive";
      case !this.contentHeader().is("h1"): return "h1, .header-responsive";
      case !this.contentHeader().is(".header-responsive"): return ".header-responsive";
      default:
        return this.contentHeader()[0].nodeName;
    }
  }
};

