/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const $ = jQuery;

class Quizzes {
  constructor() {
    this.enableSubmit = this.enableSubmit.bind(this);
    $(document).on("change", "[data-behavior~=enable-submit-on-check]", this.enableSubmit);
  }

  enableSubmit(event) {
    return $(event.currentTarget.form).find("[type=submit]").removeAttr("disabled");
  }
}

new Quizzes();
