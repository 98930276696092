/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const $ = jQuery;

window.ContentItem = class ContentItem {

  constructor(name) {
    this.name = name;
  }

  item() {
    return $(`.item-${this.name}`);
  }

  update(html) {
    return this.item().html(html);
  }
};

