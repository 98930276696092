/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const $ = jQuery;

class Validations {
  static initClass() {
    this.formSelectors = "form[data-validate] input:not(.novalidate), form[data-validate] select:not(.novalidate)";
  }
  static attach() {
    $(document).on("keydown", this.formSelectors, event => {
      const validations = new Validations(event.currentTarget);
      return validations.keydown();
    });

    return $(document).on("change", this.formSelectors, event => {
      const validations = new Validations(event.currentTarget);
      return validations.changed();
    });
  }

  constructor(element) {
    this.onSuccess = this.onSuccess.bind(this);
    this.element = $(element);
  }

  keydown() {
    return this.element.siblings(".error").remove();
  }

  changed() {
    return $.ajax({
      url: this.url(),
      type: 'POST',
      data: this.form().serializeArray(),
      success: this.onSuccess
    });
  }

  onSuccess(data) {
    const newHtml = $(data).find(this.elemantNameSelector()).parent();
    return this.element.parent().replaceWith(newHtml);
  }

  elemantNameSelector() {
    return `[name=\"${this.element.attr("name")}\"]`;
  }

  form() {
    return this.element.closest("form");
  }

  url() {
    return this.form().attr('action') + "/validate";
  }
}
Validations.initClass();

Validations.attach();
