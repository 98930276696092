/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const $ = jQuery;

class AccidentalClose {
  static initClass() {
    this.prototype.preventCloseSelectors = "[data-behavior~=prevent-close], [data-behavior~=prevent-close] input, [data-behavior~=prevent-close] textarea, [data-behavior~=prevent-close] select";
    this.prototype.linkSelectors = "a:not([data-behavior~=ignore-prevent-close], [data-bs-toggle], [data-controller~=toggle-class], [data-remote], [target]), a[data-behavior~=prevent-close]";
    this.prototype.submitSelectors = "form:not([data-remote])";
  
    this.prototype.preventClose = false;
  }

  constructor() {
    this.linkClick = this.linkClick.bind(this);
    this.off = this.off.bind(this);
    this.on = this.on.bind(this);
    this.onBeforeUnload = this.onBeforeUnload.bind(this);
    this.addObservers();
  }

  addObservers() {
    window.addEventListener("beforeunload", this.onBeforeUnload);

    $(document).on("keyup", this.preventCloseSelectors, this.on);
    $(document).on("click", this.linkSelectors, this.linkClick);

    // we must turn off accidentalClose when making submit because in other case we will
    // be not able to submit our forms
    return $(document).on("submit", this.submitSelectors, this.off);
  }

  linkClick(event) {
    if (this.preventClose && !confirm("Masz niezapisane zmiany na stronie. Przechodząc dalej, zostaną utracone.")) {
      event.preventDefault();
      event.stopImmediatePropagation();
      event.stopPropagation();
    } else {
      this.preventClose = false;
    }

    return true;
  }

  off() {
    this.preventClose = false;
    return true;
  }

  on() {
    this.preventClose = true;
    return true;
  }

  onBeforeUnload(event) {
    if (this.preventClose) {
      return event.returnValue = "Masz niezapisane zmiany na stronie. Przechodząc dalej, zostaną utracone.";
    } else {
      return;
    }
  }
}
AccidentalClose.initClass();

$(() => window.accidentalClose = new AccidentalClose());
