/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const $ = jQuery;

const Cls = (window.QuizScore = class QuizScore {
  static initClass() {
    this.currentUser = null;
  
    this.refreshOne = (index, element) => {
      const currentInstance = this.instance($(element));
      return currentInstance.refresh();
    };
  }

  static quizScores() {
    return this.currentUser.quiz_scores;
  }

  static refresh() {
    if (this.currentUser) { return $("[data-behavior~=quiz-score]").each(this.refreshOne); }
  }

  static instance(element) {
    if (!element.data('quiz-score')) { element.data('quiz-score', new QuizScore(element)); }
    return element.data('quiz-score');
  }

  constructor(element) {
    this.element = element;
  }

  add() {
    this.element.addClass("quiz-score-yes");
    return this.element.find(".quiz-score-value").html(this.score());
  }

  remove() {
    this.element.removeClass("quiz-score-yes");
    return this.element.find(".quiz-score-value").empty();
  }

  score() {
    return QuizScore.quizScores()[this.id()];
  }

  refresh() {
    if (this.score() !== undefined) {
      return this.add();
    } else {
      return this.remove();
    }
  }

  id() {
    return parseInt(this.element.data('id'));
  }
});
Cls.initClass();

$(document).ready(() => QuizScore.refresh());
document.addEventListener("fdb.userUpdate", function() {
  QuizScore.currentUser = currentUser;
  return QuizScore.refresh();
});
