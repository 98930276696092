/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const $ = jQuery;

class Expire {
  static setup() {
    return $("[data-expire]").each(this.setupOne);
  }

  static setupOne(index, element) {
    return new Expire($(element));
  }

  constructor(element) {
    this.element = element;
    if (this.expired()) { this.action(); }
  }

  expired() {
    return this.expire() < (new Date());
  }

  expire() {
    return new Date(this.element.data("expire"));
  }

  action() {
    return this.element.addClass("disabled");
  }
}

$(() => Expire.setup());
