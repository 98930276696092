/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const $ = jQuery;

class AjaxPopover {
  static initClass() {
    // class methods
    this.selector = '[data-ajax-popover]';
  
    // instance methods
    this.prototype.element = null;
    //content: null
    this.prototype.visible = true;
  }
  static attach() {
    return $(document).on('mouseenter', this.selector, function(event) {
      if (!$(event.currentTarget).data('popover')) { return new AjaxPopover(event.currentTarget); }
    });
  }

  constructor(element) {
    this.show = this.show.bind(this);
    this.delayedHide = this.delayedHide.bind(this);
    this.hide = this.hide.bind(this);
    this.element = $(element);
    this.addObservers();
    this.load();
  }


  create() {
    this.element.popover({
      trigger: 'manual',
      html: true,
      animation: false,
      template: '<div class="popover popover-big"><div class="arrow"></div><div class="popover-inner"><button type="button" class="close" data-dismiss="popover">×</button><h3 class="popover-title"></h3><div class="popover-content"><p></p></div></div></div>'
    });

    return this.observerPopover();
  }


  addObservers() {
    this.element.on('mouseenter', this.show);
    return this.element.on('mouseleave', this.delayedHide);
  }

  observerPopover() {
    this.element.data('popover').tip().on('mouseenter', this.show);
    this.element.data('popover').tip().on('mouseleave', this.delayedHide);
    return this.element.data('popover').tip().find('[data-dismiss="popover"]').on('click', this.hide);
  }

  popover(action) {
    if (this.ready()) { return this.element.popover(action); }
  }

  show() {
    this.visible = true;
    return this.popover('show');
  }

  delayedHide() {
    this.visible = false;
    return setTimeout(( () => {
      if (!this.visible) { return this.hide(); }
    }
    ), 200);
  }

  hide() {
    this.visible = false;
    return this.popover('hide');
  }


  load() {
    return this.fetch(content => {
      this.setContent(content);
      this.create();
      if (this.visible) { return this.show(); }
    });
  }

  fetch(callback) {
    return $.get(this.element.data('ajax-popover'), callback);
  }

  ready() {
    return this.element.data('popover');
  }

  setContent(content) {
    return this.element.data('content', content);
  }

  getContent() {
    return this.element.data('content');
  }
}
AjaxPopover.initClass();

AjaxPopover.attach();
