/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const $ = jQuery;

const Cls = (window.Pulling = class Pulling {
  static initClass() {
    this.objects = [];
    this.disabled = false;
    this.prototype.destroyed = false;
  
    this.setup = () => {
      this.destroyAll();
      return $('[data-behavior~=pulling]:visible').each(this.create);
    };
  
    this.windowVisibilityChange = () => {
      if (document.hidden) {
        debug("Stop pulling - window hidden");
        return this.destroyAll();
      } else {
        debug("Start pulling - window visible");
        return this.setup();
      }
    };
  
    this.create = (index, element) => {
      return this.objects.push(new Pulling(element));
    };
  
    this.destroy = (object, index) => {
      this.objects.splice(index, 1);
      return object.destroy();
    };
  }

  static destroyAll() {
    return this.objects.forEach(this.destroy);
  }

  constructor(element) {
    this.schedule = this.schedule.bind(this);
    this.pull = this.pull.bind(this);
    this.done = this.done.bind(this);
    this.element = $(element);
    this.schedule();
  }

  schedule() {
    if (this.hidden() || Pulling.disabled) { this.destroy(); }
    if (!this.destroyed) { return this.timeoutId = setTimeout(this.pull, this.interval()); }
  }

  url() {
    return this.element.data("url");
  }

  type() {
    return this.element.data("type") || "html";
  }

  interval() {
    return parseInt(this.element.data("interval") || 5000);
  }

  pull() {
    debug(`Pulling ${this.url()}..`);
    return $.ajax({
      url: this.url(),
      type: 'GET',
      dataType: this.type(),
      complete: this.schedule
    }).done(this.done);
  }

  done(data) {
    if (this.type() !== "html") { return; }
    const html = $(data).find(`[data-behavior~=pulling][data-url='${this.url()}']`);
    if (this.element.html() !== html.html()) { return this.element.html(html.html()); }
  }

  destroy() {
    this.destroyed = true;
    return clearTimeout(this.timeoutId);
  }

  hidden() {
    return !this.element.is(":visible");
  }
});
Cls.initClass();

$(() => Pulling.setup());
document.addEventListener("visibilitychange", Pulling.windowVisibilityChange, false);
