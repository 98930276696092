/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const $ = jQuery;

class Lightbox2HtmlFormatter extends Lightbox2Formatter {
  imageUrl() {
    return this.item.href;
  }

  description() {
    return this.item.title;
  }

  thumbnailUrl() {
    return $(this.item).data("thumbnail-url") || $(this.item).children().attr("src");
  }
}

window.Lightbox2HtmlFormatter = Lightbox2HtmlFormatter;
