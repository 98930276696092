/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const $ = jQuery;

//#### MOVIE WIDGET!!!!! #############################
const Cls = (window.VoteWidget = class VoteWidget {
  static initClass() {

    this.onClick = event => {
      if (!$(event.target).parents().is("[data-behavior~=vote-widget]") &&
      !$(event.target).is("[data-behavior~=vote-widget]") &&
      !$(event.target).parents().is(".vote-popover")) {
         return this.close();
       }
    };

    this.attachOne = event => {
      event.preventDefault();
      this.currentInstance = this.instance($(event.currentTarget));
      return this.currentInstance.toggle();
    };
  }
  static close() {
    return $(".popover-initialized[data-behavior~=vote-widget]").each((index, element) => {
      this.instance($(element)).hide()
    });
  }

  static attach() {
    $(document).on("click", '[data-behavior~=vote-widget]', this.attachOne);
    return $(document).on("click", this.onClick);
  }

  static instance(element) {
    if (!element.data('vote-widget')) { element.data('vote-widget', new VoteWidget(element)); }
    return element.data("vote-widget");
  }

  // Instance methods
  constructor(element) {
    this.hide = this.hide.bind(this);
    this.render = this.render.bind(this);
    this.contentForWatch = this.contentForWatch.bind(this);
    this.content = this.content.bind(this);
    this.element = element;
    this.initializePopover();
    this.element.addClass("popover-initialized");
    this.element.on("inserted.bs.popover", this.render);
  }

  hide() {
    return this.element.popover('hide');
  }

  toggle() {
    return this.element.popover('toggle');
  }

  id() {
    return this.element.data("id");
  }

  popover() {
    return $(bootstrap.Popover.getInstance(this.element[0]).tip);
  }

  initializePopover() {
    return this.element.popover({
      trigger: 'manual',
      placement: 'bottom',
      container: 'body',
      html: true,
      content: this.content,
      template: this.template(),
      animation: false,
      sanitize: false
    });
  }

  refreshVote() {}

  template() {
    return `\
<div class="popover vote-popover">
  <div class="arrow"></div>
  <h3 class="popover-title"></h3>
  <div class="popover-content popover-body"></div>
</div>\
`;
  }

  url() {
    return this.element.data("url");
  }

  render() {
    if (this.renderElement("favourite")) { this.renderFavourite(); }
    if (this.renderElement("watch")) { this.renderWatch(); }
    if (this.renderElement("vote")) { return this.renderVote(); }
  }

  renderVote() {
    const voteWidgetVote = new VoteWidgetVote(this.popover().find(".vote-widget-vote"));
    return voteWidgetVote.render();
  }

  renderFavourite() {
    const voteWidgetFavourite = new VoteWidgetFavourite(this.popover().find(".vote-widget-favourite"));
    return voteWidgetFavourite.render();
  }

  renderWatch() {
    const voteWidgetWatch = new VoteWidgetWatch(this.popover().find(".vote-widget-watch"));
    return voteWidgetWatch.render();
  }

  renderElement(name) {
    return this.element.data("elements") && ($.inArray(name, this.element.data("elements").split(" ")) > -1);
  }

  contentForVote() {
    return `\
<div class="vote-widget-vote" data-url="${this.url()}/vote" data-id="${this.id()}"></div>\
`;
  }

  contentForFavourite() {
    return `\
<div class="vote-widget-favourite pad-10 mt-2" data-url="${this.url()}/lists/favourites/list_items" data-id="${this.id()}"></div>\
`;
  }

  contentForWatch() {
    return `\
<div class="vote-widget-watch pad-10 mt-2" data-url="${this.url()}/lists/watch/list_items" data-id="${this.id()}"></div>\
`;
  }

  content() {
    let content = "";
    if (this.renderElement("vote")) { content += this.contentForVote(); }
    if (this.renderElement("favourite")) { content += this.contentForFavourite(); }
    if (this.renderElement("watch")) { content += this.contentForWatch(); }
    return content;
  }
});
Cls.initClass();

VoteWidget.attach();
