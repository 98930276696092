/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const $ = jQuery;

class ImageUploader {
  constructor() {
    this.submit = this.submit.bind(this);
    this.always = this.always.bind(this);
    this.insertThumbnail = this.insertThumbnail.bind(this);
    this.insertImage = this.insertImage.bind(this);
    this.setup();
  }

  disableSubmitForm(event) {
    event.preventDefault();
    return alert("Musisz poczekać aż wysyłanie pliku dobiegnie końca");
  }

  formMethod(form) {
    return form.find("[name=_method]");
  }

  forcePostMethod(form) {
    const method = this.formMethod(form);
    if (!method.data("method")) { method.data("method", method.val()); }
    return method.val("post");
  }

  restoreOryginalMethod(form) {
    const method = this.formMethod(form);
    // protect for multiple file upload at the same time
    if (method.data("method")) {
      method.val(method.data("method"));
      return method.data("method", null);
    }
  }

  submit(event, data) {
    return this.forcePostMethod(data.form);
  }

  always(event, data) {
    return this.restoreOryginalMethod(data.form);
  }


  setup() {
    this.setupFileupload();
    return this.setupObservers();
  }

  setupObservers() {
    this.observeInsertImage();
    return this.observeInsertThumbnail();
  }

  setupFileupload() {
    return $('#image_uploaded_file').fileupload({
      dataType: 'script',
      submit: this.submit,
      always: this.always
    });
  }

  appendImage(html) {
    return $("#images").prepend(html);
  }

  removeImage(id) {
    $(`#image-${id}`).remove();
    return $(`.image-${id}`).remove();
  }

  observeInsertImage() {
    return $("#images").on("click", "[data-image]", this.insertImage);
  }

  observeInsertThumbnail() {
    return $("#images").on("click", "[data-thumbnail]", this.insertThumbnail);
  }

  insertThumbnail(event) {
    event.preventDefault();

    const key = $(event.currentTarget).data("key");
    const target = $(event.currentTarget).data("target");

    return $(target).textareaInsert(`{{thumbnail key=\"${key}\" width=\"50%|33%\" ratio=\"square|vertical|horizontal\"}}`);
  }

  insertImage(event) {
    event.preventDefault();

    const key = $(event.currentTarget).data("key");
    const target = $(event.currentTarget).data("target");
    return $(target).textareaInsert(`{{image key=\"${key}\" title=\"podpis pod obrazkiem\"}}`);
  }
}

$(() => window.imageUploader = new ImageUploader);
