/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const $ = jQuery;

class Focus {
  constructor() {
    $("[data-focus]").on("focus", this.onFocus);
    $("[data-focus]").on("blur", this.onBlur);
  }

  onFocus(event) {
    return $($(event.currentTarget).data("focus")).addClass("focus");
  }

  onBlur(event) {
    return $($(event.currentTarget).data("focus")).removeClass("focus");
  }
}

new Focus();
