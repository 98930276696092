import { faAd } from '@madmax/fontawesome-pro-light-svg-icons/faAd'
import { faAlignCenter } from '@madmax/fontawesome-pro-light-svg-icons/faAlignCenter'
import { faAngleDoubleLeft } from '@madmax/fontawesome-pro-light-svg-icons/faAngleDoubleLeft'
import { faAngleDoubleRight } from '@madmax/fontawesome-pro-light-svg-icons/faAngleDoubleRight'
import { faAngleRight } from '@madmax/fontawesome-pro-light-svg-icons/faAngleRight'
import { faArrowRight } from '@madmax/fontawesome-pro-light-svg-icons/faArrowRight'
import { faBadgeDollar } from '@madmax/fontawesome-pro-light-svg-icons/faBadgeDollar'
import { faBell } from '@madmax/fontawesome-pro-light-svg-icons/faBell'
import { faBold } from '@madmax/fontawesome-pro-light-svg-icons/faBold'
import { faBullhorn } from '@madmax/fontawesome-pro-light-svg-icons/faBullhorn'
import { faCalendar } from '@madmax/fontawesome-pro-light-svg-icons/faCalendar'
import { faChartBar } from '@madmax/fontawesome-pro-light-svg-icons/faChartBar'
import { faCheck } from '@madmax/fontawesome-pro-light-svg-icons/faCheck'
import { faCheckSquare } from '@madmax/fontawesome-pro-light-svg-icons/faCheckSquare'
import { faChevronDoubleLeft } from '@madmax/fontawesome-pro-light-svg-icons/faChevronDoubleLeft'
import { faChevronDoubleRight } from '@madmax/fontawesome-pro-light-svg-icons/faChevronDoubleRight'
import { faChevronDoubleUp } from '@madmax/fontawesome-pro-light-svg-icons/faChevronDoubleUp'
import { faChevronDown } from '@madmax/fontawesome-pro-light-svg-icons/faChevronDown'
import { faChevronLeft } from '@madmax/fontawesome-pro-light-svg-icons/faChevronLeft'
import { faChevronRight } from '@madmax/fontawesome-pro-light-svg-icons/faChevronRight'
import { faChevronUp } from '@madmax/fontawesome-pro-light-svg-icons/faChevronUp'
import { faClock } from '@madmax/fontawesome-pro-light-svg-icons/faClock'
import { faClone } from '@madmax/fontawesome-pro-light-svg-icons/faClone'
import { faCloudDownload } from '@madmax/fontawesome-pro-light-svg-icons/faCloudDownload'
import { faCode } from '@madmax/fontawesome-pro-light-svg-icons/faCode'
import { faCommentAltExclamation } from '@madmax/fontawesome-pro-light-svg-icons/faCommentAltExclamation'
import { faComments } from '@madmax/fontawesome-pro-light-svg-icons/faComments'
import { faCopy } from '@madmax/fontawesome-pro-light-svg-icons/faCopy'
import { faDownload } from '@madmax/fontawesome-pro-light-svg-icons/faDownload'
import { faEdit } from '@madmax/fontawesome-pro-light-svg-icons/faEdit'
import { faEllipsisV } from '@madmax/fontawesome-pro-light-svg-icons/faEllipsisV'
import { faEnvelope } from '@madmax/fontawesome-pro-light-svg-icons/faEnvelope'
import { faExclamation } from '@madmax/fontawesome-pro-light-svg-icons/faExclamation'
import { faExclamationCircle } from '@madmax/fontawesome-pro-light-svg-icons/faExclamationCircle'
import { faExternalLink } from '@madmax/fontawesome-pro-light-svg-icons/faExternalLink'
import { faEye } from '@madmax/fontawesome-pro-light-svg-icons/faEye'
import { faEyeSlash } from '@madmax/fontawesome-pro-light-svg-icons/faEyeSlash'
import { faFileCsv } from '@madmax/fontawesome-pro-light-svg-icons/faFileCsv'
import { faFilm } from '@madmax/fontawesome-pro-light-svg-icons/faFilm'
import { faH1 } from '@madmax/fontawesome-pro-light-svg-icons/faH1'
import { faH2 } from '@madmax/fontawesome-pro-light-svg-icons/faH2'
import { faH3 } from '@madmax/fontawesome-pro-light-svg-icons/faH3'
import { faHeart } from '@madmax/fontawesome-pro-light-svg-icons/faHeart'
import { faHome } from '@madmax/fontawesome-pro-light-svg-icons/faHome'
import { faImages } from '@madmax/fontawesome-pro-light-svg-icons/faImages'
import { faInfoCircle } from '@madmax/fontawesome-pro-light-svg-icons/faInfoCircle'
import { faItalic } from '@madmax/fontawesome-pro-light-svg-icons/faItalic'
import { faLink } from '@madmax/fontawesome-pro-light-svg-icons/faLink'
import { faList } from '@madmax/fontawesome-pro-light-svg-icons/faList'
import { faListOl } from '@madmax/fontawesome-pro-light-svg-icons/faListOl'
import { faListUl } from '@madmax/fontawesome-pro-light-svg-icons/faListUl'
import { faLongArrowLeft } from '@madmax/fontawesome-pro-light-svg-icons/faLongArrowLeft'
import { faLongArrowRight } from '@madmax/fontawesome-pro-light-svg-icons/faLongArrowRight'
import { faMars } from '@madmax/fontawesome-pro-light-svg-icons/faMars'
import { faMinusSquare } from '@madmax/fontawesome-pro-light-svg-icons/faMinusSquare'
import { faPencil } from '@madmax/fontawesome-pro-light-svg-icons/faPencil'
import { faPeopleArrows } from '@madmax/fontawesome-pro-light-svg-icons/faPeopleArrows'
import { faPlayCircle } from '@madmax/fontawesome-pro-light-svg-icons/faPlayCircle'
import { faPlus } from '@madmax/fontawesome-pro-light-svg-icons/faPlus'
import { faPlusCircle } from '@madmax/fontawesome-pro-light-svg-icons/faPlusCircle'
import { faQuestion } from '@madmax/fontawesome-pro-light-svg-icons/faQuestion'
import { faQuestionCircle } from '@madmax/fontawesome-pro-light-svg-icons/faQuestionCircle'
import { faQuoteLeft } from '@madmax/fontawesome-pro-light-svg-icons/faQuoteLeft'
import { faRepeat } from '@madmax/fontawesome-pro-light-svg-icons/faRepeat'
import { faSearch } from '@madmax/fontawesome-pro-light-svg-icons/faSearch'
import { faShare } from '@madmax/fontawesome-pro-light-svg-icons/faShare'
import { faSignInAlt } from '@madmax/fontawesome-pro-light-svg-icons/faSignInAlt'
import { faSitemap } from '@madmax/fontawesome-pro-light-svg-icons/faSitemap'
import { faSquare } from '@madmax/fontawesome-pro-light-svg-icons/faSquare'
import { faStar } from '@madmax/fontawesome-pro-light-svg-icons/faStar'
import { faStrikethrough } from '@madmax/fontawesome-pro-light-svg-icons/faStrikethrough'
import { faTable } from '@madmax/fontawesome-pro-light-svg-icons/faTable'
import { faTasks } from '@madmax/fontawesome-pro-light-svg-icons/faTasks'
import { faThumbsDown } from '@madmax/fontawesome-pro-light-svg-icons/faThumbsDown'
import { faThumbsUp } from '@madmax/fontawesome-pro-light-svg-icons/faThumbsUp'
import { faTicketAlt } from '@madmax/fontawesome-pro-light-svg-icons/faTicketAlt'
import { faTimes } from '@madmax/fontawesome-pro-light-svg-icons/faTimes'
import { faTrash } from '@madmax/fontawesome-pro-light-svg-icons/faTrash'
import { faTrophy } from '@madmax/fontawesome-pro-light-svg-icons/faTrophy'
import { faTrophyAlt } from '@madmax/fontawesome-pro-light-svg-icons/faTrophyAlt'
import { faTv } from '@madmax/fontawesome-pro-light-svg-icons/faTv'
import { faUser } from '@madmax/fontawesome-pro-light-svg-icons/faUser'
import { faUserCheck } from '@madmax/fontawesome-pro-light-svg-icons/faUserCheck'
import { faUserMinus } from '@madmax/fontawesome-pro-light-svg-icons/faUserMinus'
import { faUserPlus } from '@madmax/fontawesome-pro-light-svg-icons/faUserPlus'
import { faVenus } from '@madmax/fontawesome-pro-light-svg-icons/faVenus'
import { faVideo } from '@madmax/fontawesome-pro-light-svg-icons/faVideo'
import { faVoteNay } from '@madmax/fontawesome-pro-light-svg-icons/faVoteNay'
import { faVoteYea } from '@madmax/fontawesome-pro-light-svg-icons/faVoteYea'

let icons = []
icons.push(faAd)
icons.push(faAlignCenter)
icons.push(faAngleDoubleLeft)
icons.push(faAngleDoubleRight)
icons.push(faAngleRight)
icons.push(faArrowRight)
icons.push(faBadgeDollar)
icons.push(faBell)
icons.push(faBold)
icons.push(faBullhorn)
icons.push(faCalendar)
icons.push(faChartBar)
icons.push(faCheck)
icons.push(faCheckSquare)
icons.push(faChevronDoubleLeft)
icons.push(faChevronDoubleRight)
icons.push(faChevronDoubleUp)
icons.push(faChevronDown)
icons.push(faChevronLeft)
icons.push(faChevronRight)
icons.push(faChevronUp)
icons.push(faClock)
icons.push(faClone)
icons.push(faCloudDownload)
icons.push(faCode)
icons.push(faCommentAltExclamation)
icons.push(faComments)
icons.push(faCopy)
icons.push(faDownload)
icons.push(faEdit)
icons.push(faEllipsisV)
icons.push(faEnvelope)
icons.push(faExclamation)
icons.push(faExclamationCircle)
icons.push(faExternalLink)
icons.push(faEye)
icons.push(faEyeSlash)
icons.push(faFileCsv)
icons.push(faFilm)
icons.push(faH1)
icons.push(faH2)
icons.push(faH3)
icons.push(faHeart)
icons.push(faHome)
icons.push(faImages)
icons.push(faInfoCircle)
icons.push(faItalic)
icons.push(faLink)
icons.push(faList)
icons.push(faListOl)
icons.push(faListUl)
icons.push(faLongArrowLeft)
icons.push(faLongArrowRight)
icons.push(faMars)
icons.push(faMinusSquare)
icons.push(faPencil)
icons.push(faPeopleArrows)
icons.push(faPlayCircle)
icons.push(faPlus)
icons.push(faPlusCircle)
icons.push(faQuestion)
icons.push(faQuestionCircle)
icons.push(faQuoteLeft)
icons.push(faRepeat)
icons.push(faSearch)
icons.push(faShare)
icons.push(faSignInAlt)
icons.push(faSitemap)
icons.push(faSquare)
icons.push(faStar)
icons.push(faStrikethrough)
icons.push(faTable)
icons.push(faTasks)
icons.push(faThumbsDown)
icons.push(faThumbsUp)
icons.push(faTicketAlt)
icons.push(faTimes)
icons.push(faTrash)
icons.push(faTrophy)
icons.push(faTrophyAlt)
icons.push(faTv)
icons.push(faUser)
icons.push(faUserCheck)
icons.push(faUserMinus)
icons.push(faUserPlus)
icons.push(faVenus)
icons.push(faVideo)
icons.push(faVoteNay)
icons.push(faVoteYea)

export default icons
