var Hide  = initialize((function($) {
  var Class = {
    // class definition goes here
    className: "hide_before_ready",

    afterReady: function() {
      this.removeHideAfterReady();
    },

    removeHideAfterReady: function() {
      $("." + this.className).removeClass(this.className);
    }
  }
  return Class;
})(jQuery));
