/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const $ = jQuery;

window.PostscribeHandler = class PostscribeHandler {
  constructor(selector, content) {
    this.done = this.done.bind(this);
    this.selector = selector;
    this.content = content;
  }

  call() {
    return postscribe(this.selector, this.content, {done: this.done});
  }

  done() {
    $(document).trigger(`fdb.postscribeDone${this.selector}`);
    return $(document).trigger("fdb.postscribeDone", this.selector);
  }
};

