window.Spinner = initialize((function($) {
  var self = {
    spinner: '.spinner',
    timerId: null,
    defaultDelay: 500,

    show: function(delay) {
      delay = delay || this.defaultDelay
      if (delay == 'now') { delay = 0 }
      if (this.timerId == null) {
        self.timerId = setTimeout(function() {
          $(self.spinner).removeClass('hidden d-none');
        },
        delay);
      }
    },

    hide: function() {
      this.clearTimeout(self.timerId)
      $(self.spinner).addClass('hidden d-none');
    },

    clearTimeout: function() {
      clearTimeout(self.timerId)
      self.timerId = null
    }
  };

  return self;
})(jQuery));


Dom.ajaxComplete(function() { Spinner.hide() })

jQuery(document).ready(function() {
  jQuery(document).on("ajax:before", "*[data-remote]:not([data-spinner=false])", function() {
    Spinner.show();
  });

  jQuery(document).ajaxStop(function() {
    Spinner.hide();
  });



});

