import { Controller } from "@hotwired/stimulus"
import { useIntersection } from "stimulus-use";

export default class extends Controller {
  connect() {
    this.loaded = false
    useIntersection(this, this.options)
  }

  appear() {
    if (!this.loaded) this.loadMore()
  }

  loadMore() {
    this.loaded = true
    debug("Loading next url", this.nextUrl)
    Rails.ajax({ dataType: "script", type: "GET", url: this.nextUrl })
  }

  get options() {
    return { rootMargin: this.margin }
  }

  get nextUrl() {
    return this.data.get("nextUrl")
  }

  get margin() {
    return this.data.get("margin") || "0px"
  }
}
