/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const $ = jQuery;

window.VoteParser = class VoteParser {
  constructor(votes) {
    this.votes = votes;
  }

  parse() {
    const results = {};
    for (let type in this.votes) { const votesForType = this.votes[type]; results[type] = this.parseType(type, votesForType); }
    return results;
  }

  parseType(type, votes) {
    const results = {};
    for (let value in votes) { const ids = votes[value]; this.parseVote(results, value, ids); }
    return results;
  }

  parseVote(results, value, ids) {
    return Array.from(ids).map((id) =>
      (results[id] = parseInt(value)));
  }
};
