import queryObject from "query-object"

const $ = jQuery;

class AuthenticationModal {
  static initClass() {
    this.prototype.lastTarget = null;
    this.prototype.lastUrl = null;
    this.prototype.currentUser = {id: null};
    this.prototype._modal = null;
  }
  constructor() {
    this.onSignupClick = this.onSignupClick.bind(this);
    this.onSigninClick = this.onSigninClick.bind(this);
    this.onAjaxBefore = this.onAjaxBefore.bind(this);
    this.onAuthenticateClick = this.onAuthenticateClick.bind(this);
    this.onDocumentReady = this.onDocumentReady.bind(this);
    this.showOnDocumentReady = this.showOnDocumentReady.bind(this);
    this.onTabShown = this.onTabShown.bind(this);
    this.addObservers();
  }

  addObservers() {
    $(document).on("click", "[data-signup]", this.onSignupClick);
    $(document).on("click", "[data-signin]", this.onSigninClick);
    $(document).on("click", "[data-authenticate]", this.onAuthenticateClick);
    $(document).on("click", "[data-behavior~=authenticate]", this.onAuthenticateClick);
    $(document).on("ajax:before", this.onAjaxBefore);
    $(document).on("shown.bs.tab", "#signup-tab, #signin-tab", this.onTabShown);
    $(document).ready(this.onDocumentReady);
    return document.addEventListener("fdb.userUpdate", () => {
      return this.currentUser = currentUser;
    });
  }


  modal() {
    return this._modal != null ? this._modal : (this._modal = new bootstrap.Modal(this.container()[0]));
  }

  onSignupClick(event) {
    event.preventDefault();
    return this.show("#signup");
  }

  onSigninClick(event) {
    event.preventDefault();
    return this.show("#signin");
  }

  onAjaxBefore(event) {
    if (($(event.target).data('authenticate') || ($(event.target).filter("[data-behavior~=authenticate]").length > 0))  && !this.currentUser.id) {
      Spinner.hide();
      return false;
    }
  }

  onAuthenticateClick(event) {
    if (!this.currentUser.id) {
      event.preventDefault();
      event.stopImmediatePropagation();
      event.stopPropagation();
      this.lastTarget = event.currentTarget;
      return this.show("#signup");
    }
  }

  onDocumentReady() {
    if (window.location.hash === "#show-signin") {
      return setTimeout(this.showOnDocumentReady, 100);
    }
  }

  container() {
    return $("#authentication-modal");
  }


  showOnDocumentReady() {
    this.lastUrl = queryObject.parse(location.search).back_url
    return this.show("#signin");
  }

  show(name) {
    this.setupRememberMe();
    this.modal().show();
    if (name) {
      this.changeTab(name);
      return this.focus(name);
    }
  }

  setupRememberMe() {
    return $('#remember').prop('checked', $('meta[name=remember-me]').attr('content') === "true");
  }

  hide() {
    return this.modal().hide();
  }

  hideAndPerformLastAction() {
    this.hide();
    if (this.lastTarget) {
      this.performLastAction();
      this.lastTarget = null;
    }
    if (this.lastUrl) {
      return window.location.href = this.lastUrl;
    }
  }

  performLastAction() {
    if (this.lastTarget == null || $(this.lastTarget).data("action") === "reload") {
      return location.reload();
    } else {
      return this.lastTarget.click();
    }
  }

  changeTab(name) {
    return $(`${name}-tab`).tab("show");
  }

  onTabShown(event) {
    return this.focus($(event.target).attr("href"));
  }

  focus(name) {
    return $(name).find("[data-focus]").focus();
  }
}
AuthenticationModal.initClass();

window.authenticationModal = new AuthenticationModal();
