class AjaxBeforeSendListener  {
  start() {
    document.addEventListener("ajax:beforeSend",  this.onBeforeSend.bind(this))
  }

  onBeforeSend(event) {
    let [xhr, options] = event.detail
    xhr.setRequestHeader('X-Variant', this.variant.content)
  }

  get variant() {
    return document.querySelector('meta[name=variant]')
  }
}

const listener = new AjaxBeforeSendListener()
listener.start()
