/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const $ = jQuery;

class AdsUnit {
  constructor(size, name, container) {
    this.size = size;
    this.name = name;
    this.container = container;
  }

  height() {
    return this.size.split("x")[1];
  }
}

const Cls = (window.AdsReplacment = class AdsReplacment {
  static initClass() {
    this.prototype.units = {
      "ads1.1": new AdsUnit('970x250', 'bilboard1-replacment-yieldbird-970x250', '.ads-bilboard1'),
      "ads1.2": new AdsUnit('728x90',  'bilboard1-replacment-yieldbird-728x90', '.ads-bilboard1'),
      "ads2"  : new AdsUnit('300x250', 'rectangle1-replacment-yieldbird', '.ads-rectangle1'),
      "ads4"  : new AdsUnit('300x600', 'skyscraper1-replacment-yieldbird', '.ads-skyscraper1'),
      "spolecznosci1.1": new AdsUnit('970x250', 'bilboard1-replacment-spolecznosci', '.ads-bilboard1'),
      "spolecznosci1.2": new AdsUnit('728x90',  'bilboard1-replacment-spolecznosci', '.ads-bilboard1'),
      "spolecznosci2":   new AdsUnit('300x250', 'rectangle1-replacment-spolecznosci', '.ads-rectangle1')
    };
  }

  constructor(unit) {
    this.unit = this.units[unit];
  }

  replace() {
    if (!this.unit) { return; }
    $(this.unit.container).html(this.template());
    return $(this.unit.container).height(this.unit.height());
  }

  template() {
    return `\
<!-- name: ${this.unit.name}, size: ${this.unit.size} -->
<div class="ads-${this.unit.name}">
  <script>
    ads('${this.unit.name}')
  </script>
</div>\
`;
  }
});
Cls.initClass();

