import { useState, createElement } from "react"

export default function AsyncPaginateControlCache(props) {
  const [cache, setCache] = useState(1)

  if (props.cache == false) {
    props = {
      ...props,
      "cacheUniqs": [cache],
      "onInputChange": () => setCache(Date.now())
    }
  }

  return createElement(props.component, { ...props } , null)
}
