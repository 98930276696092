/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const $ = jQuery;

class Premiere {
  static setup() {
    return $("[data-premiere]").each(this.setupOne);
  }

  static setupOne(index, element) {
    if ($(element).parents("[data-premiere-skip]").length === 0) { return new Premiere(element); }
  }

  template() {
    return '<span class="badge badge-ribbon badge-secondary label label-default label-ribbon">PREMIERA</span>';
  }

  prePremiereTemplate() {
    return '<span class="badge badge-ribbon badge-primary label label-primary label-ribbon label-ribbon-2-lines">PRZED<br>PREMIERA</span>';
  }

  constructor(element) {
    this.element = $(element);
    if (this.isPremiere()) { this.markAsPremiere(); }
    if (this.isPrePremiere()) { this.markAsPrePremiere(); }
  }

  isPremiere() {
    return (this.premiereStart() < this.today()) && (this.premiereEnd() > this.today());
  }

  isPrePremiere() {
    return this.prePremiereEnd() > this.today();
  }

  markAsPremiere() {
    return this.element.parent().append(this.template());
  }

  markAsPrePremiere() {
    return this.element.parent().append(this.prePremiereTemplate());
  }

  today() {
    return (new Date()).getTime();
  }

  premiere() {
    return (new Date(this.element.data("premiere"))).getTime();
  }

  premiereStart() {
    return this.premiere() - (86400000 * 6);
  }

  premiereEnd() {
    return this.premiere() + (86400000 * 6);
  }

  prePremiereEnd() {
    return this.premiereStart();
  }
}

$(() => Premiere.setup());
