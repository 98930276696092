/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const $ = jQuery;

class CharsCounter {
  static setup() {
    this.attach();
    $(document).ajaxComplete(() => {
      return this.attach();
    });
    return Dom.ajaxComplete(() => this.attach());
  }

  static attach() {
    return $("[data-chars-counter]").each((index, element) => new CharsCounter(element));
  }

  constructor(element) {
    this.update = this.update.bind(this);
    this.element = $(element);
    this.observeChange();
    this.update();
  }

  observeChange() {
    return this.element.on("keyup", this.update);
  }

  update() {
    this.target().html(this.getCharsCount());
    if (!this.max()) { return; }

    if (this.getCharsCount() > this.max()) {
      return this.target().addClass("text-danger");
    } else {
      return this.target().removeClass("text-danger");
    }
  }

  getCharsCount() {
    return this.element.val().length;
  }

  max() {
    return parseInt(this.element.data("max"));
  }

  target() {
    return $(this.element.data("target"));
  }
}


$(() => CharsCounter.setup());
