/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const $ = jQuery;

class AdsPlaceholders {
  rectangle() {}

  bilboard() {
    $('.ads-bilboard1').append(this.bilboardTemplate());
    const instance = this.reviveInstance();
    if (instance && instance.done) {
      instance.done = false;
      return instance.start();
    }
  }

  bilboardTemplate() {
    return `\
<ins data-revive-zoneid="18" data-revive-id="56f1db58f47be4db95e3cb152ca9e6f9"></ins>
<script async src="//ad.fdb.pl/dd/asyncjs.php"></script>\
`;
  }


  reviveInstance() {
    if (typeof(window.reviveAsync) === "object") {
      let instance;
      const instanceKey = Object.keys(window.reviveAsync)[0];
      return instance = window.reviveAsync[instanceKey];
    }
  }
}


window.adsPlaceholders = new AdsPlaceholders();
