/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const $ = jQuery;

class Popup {
  constructor() {
    this.open = this.open.bind(this);
    $("[data-popup]").on("click", this.open);
  }

  open(event) {
    const element = $(event.currentTarget);
    event.preventDefault();
    return $.popupWindow(element.attr('href'), {width: 1200, height: 800, center: 'parent', resizable: true});
  }
}

$(() => new Popup());
