/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const $ = jQuery;

$(document).on("click", '[data-behavior~=collapse-and-focus]', function(event) {
  const target = $($(event.currentTarget).data("target"));
  const input = $(target).find(".focus");

  if (target.hasClass("show") || target.hasClass("in")) {
    target.collapse('hide');
    return input.blur();
  } else {
    target.collapse("show");
    return input.focus();
  }
});

