/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const $ = jQuery;

class HeightLimiter {
  static initClass() {
  
    this.attachOne = (index, element) => {
      window.limiter = new HeightLimiter(element);
      return window.limiter.truncate();
    };
  }
  static attach() {
    return $("[data-limiter]").each(this.attachOne);
  }

  constructor(element) {
    this.element = $(element);
  }

  truncate() {
    if (this.anyItem() && this.needTruncate()) {
      this.lastItem().hide();
      return this.truncate();
    }
  }

  anyItem() {
    return this.lastItem().size() > 0;
  }

  needTruncate() {
    return this.current() > this.max();
  }

  max() {
    return this.element.data("max") || 100000;
  }

  tag() {
    return this.element.data("tag") || "li";
  }

  visibleTag() {
    return `${this.tag()}:visible`;
  }

  lastItem() {
    return this.element.find(this.visibleTag()).last();
  }

  mode() {
    return this.element.data("mode") || "height";
  }

  current() {
    if (this.mode() === "height") {
      return this.element.height();
    } else {
      return this.lastItem().position().top + this.lastItem().height();
    }
  }
}
HeightLimiter.initClass();

$(() => HeightLimiter.attach());
