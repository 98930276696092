// used by fileupload
import "./jquery.ui.widget"
import "./jquery.cookie"
import "./jquery.soulmate"
import "./jquery.truncator"
import "./jquery.remove-class-regex"
import "./jquery.popupwindow"

// lightbox dependencies
import "./jquery.touchSwipe"
import "./jquery.tools.min"

import "bootstrap-datepicker/js/bootstrap-datepicker"
import "bootstrap-datepicker/js/locales/bootstrap-datepicker.pl"

import "blueimp-file-upload/jquery.fileupload"
import "blueimp-file-upload/jquery.iframe-transport"


import "shave/dist/jquery.shave"
