/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const $ = jQuery;
window.AdsScreening = class AdsScreening {

  static display(image, url, view) {
    const screening = new AdsScreening(image, url, view);
    return screening.display();
  }

  constructor(image, url, view) {
    this.image = image;
    this.url = url;
    this.view = view;
  }

  display() {
    $('body').addClass('ads-screening');

    if (this.view === "fixed") {
      $('body').addClass('ads-screening-fixed');
      $('.background').append('<div class="background-fixed"></div>');
      $('.background-fixed').css({"background-image": `url(${this.image})`});
    } else {
      $('.background').css({"background-image": `url(${this.image})`});
    }

    return $('.background').on("click", event => {
      const target = $(event.target);
      if (target.hasClass('background') || target.hasClass('ads-bilboard1') || (target.parent(".ads-bilboard1").size() > 0)) {
        return window.open(this.url,'_blank');
      }
    });
  }
};

