/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const $ = jQuery;

const Cls = (window.ListItem = class ListItem {
  static initClass() {
    this.currentUser = null;
  }

  static add(itemKind, itemId, listSlug)  {
    const l = this.listItems();

    if (l[itemKind] == null) { l[itemKind] = {}; }
    if (l[itemKind][itemId] == null) { l[itemKind][itemId] = []; }
    return l[itemKind][itemId].push(listSlug);
  }

  static remove(itemKind, itemId, listSlug) {
    const l = this.listItems();

    if (l[itemKind] && l[itemKind][itemId]) {
      const index = l[itemKind][itemId].indexOf(listSlug);
      if (index > -1) { return l[itemKind][itemId].splice(index, 1); }
    }
  }

  static listItems() {
    return this.currentUser.list_items;
  }

  static refresh() {
    if (this.currentUser) { return $("[data-behavior~=list-item]").each(this.refreshOne); }
  }

  static refreshOne(index, element) {
    const listItem = new ListItem($(element));
    return listItem.refresh();
  }

  constructor(element) {
    this.element = element;
  }

  listItems() {
    return ListItem.listItems()[this.kind()] || {};
  }

  markAsInList() {
    return this.element.addClass("list-yes");
  }

  markAsInLists() {
    this.markAsInList();

    // for purecss
    // list-favourites-yes
    // list-watch-yes
    this.element.addClass((this.inLists().map(listSlug => `list-${listSlug}-yes`)).join(" "));
    return this.element.find(".lists-count").html(this.inLists().length);
  }

  markAsNotInLists() {
    this.element.removeClassRegex(/^list-/);
    return this.element.find(".lists-count").empty();
  }

  refresh() {
    this.markAsNotInLists();

    if (this.listSlug() && this.inList()) { this.markAsInList(); }
    if (!this.listSlug() &&  (this.inLists().length > 0)) { return this.markAsInLists(); }
  }

  inLists() {
    return this.listItems()[this.id()] || [];
  }

  inList() {
    return $.inArray(this.listSlug(), this.inLists()) >= 0;
  }

  id() {
    return parseInt(this.element.data('id').split(/-/)[1]);
  }

  kind() {
    return this.element.data('id').split(/-/)[0];
  }

  listSlug() {
    let slug = this.element.data('list');
    if (slug) { slug = String(slug); }
    return slug;
  }
});
Cls.initClass();

Dom.ajaxComplete(() => Ability.reload());
$(document).ajaxStop(() => ListItem.refresh());
$(document).ready(() => ListItem.refresh());
document.addEventListener("fdb.userUpdate", function() {
  ListItem.currentUser = currentUser;
  return ListItem.refresh();
});
