/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
//Usage
//
//<script>
//  window.affilateLinksAsyncInit = function(replace) {
//    link("url.to.replace", "https://affilate.link?dst={{url}}")
//    phrase("Showmax", ...)
//  }
//</script>
//<script type="text/javascript" src="http://myapple.dev/javascripts/affilate_links.js"></script>
//
//

//fdb("affilate", "pharse", "Showmax", "https://affilate.link")
//fdb("affilate", "link", "showmax.pl", "https://affilate.link?{{url}}")
const $ = jQuery;

const Cls = (window.Affilate = class Affilate {
  static initClass() {
    this.debug = false;
    this.forceContexts = { div: 1, article: 1,  p: 1, section: 1,  table: 1, tbody: 1, tr: 1,  td: 1 };
    this.filterElements = { br:1, hr:1, script:1, style:1, img:1, video:1, audio:1, canvas:1, svg:1, map:1, object:1, input:1, textarea:1, select:1, option:1, optgroup: 1, button:1, a:1, nav:1 };
  }
  static link(pattern, link) {
    const affilate = new Affilate(pattern, link);
    return affilate.link();
  }

  static pharse(pattern, link) {
    const affilate = new Affilate(pattern, link);
    return affilate.pharse();
  }

  constructor(pattern, affilateLink) {
    this.pharseOne = this.pharseOne.bind(this);
    this.linkOne = this.linkOne.bind(this);
    this.pattern = pattern;
    this.affilateLink = affilateLink;
  }

  pharse() {
    return findAndReplaceDOMText(document.querySelector("body"), {
      find: this.pharseFindPattern(),
      replace: this.pharseOne,
      forceContext: this.forceContext,
      filterElements: this.filterElements
    });
  }

  pharseFindPattern() {
    if (typeof this.pattern === "string") {
      return new RegExp(`${this.pattern}`, 'ig');
    } else {
      return this.pattern;
    }
  }

  forceContext(element) {
    debug("forceContext", element.nodeName.toLowerCase(), Affilate.forceContexts[element.nodeName.toLowerCase()]);
    return Affilate.forceContexts[element.nodeName.toLowerCase()] === 1;
  }

  filterElements(element) {
    debug("FilterElements", element.nodeName.toLowerCase(), Affilate.filterElements[element.nodeName.toLowerCase()]);
    return Affilate.filterElements[element.nodeName.toLowerCase()] !== 1;
  }


  pharseOne(portion, match) {
    debug("Replacing", portion, match);
    return $(`<a href='${this.affilateLink}' rel='nofollow' >${portion.text}</a>`)[0];
  }

  link() {
    return $(`a[href*='${this.pattern}']`).each(this.linkOne);
  }

  linkOne(index, element) {
    element = $(element);
    if (!element.data("affilate-link")) {
      element.data("affilate-link", true);
      return element.attr("href", this.affilateLink.replace("{{url}}", encodeURIComponent(element.attr("href"))));
    }
  }
});
Cls.initClass();

