/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const $ = jQuery;

// example
// -----------------------------------------------------
//
// <li data-ability="updateComment", data-owner-id="1">...</li>
// this will call updateComment method in Ability class
// li will be hidden if method returns false and true if it returns true
//

const Cls = (window.AbilityAbstract = class AbilityAbstract {
  static initClass() {
    this.currentUser = null;
  }
  static reload() {
    debug("reloading ability");
    if (!this.currentUser) { return; } // wait for user
    return $("[data-ability]").each((index, element) => {
      return new Ability($(element), this.currentUser);
    });
  }

  constructor(element, currentUser) {
    this.element = element;
    this.currentUser = currentUser;
    this.authorize();
  }

  authorize() {
    const method = this[this.method()];

    if (!method) { throw `Not defined ability ${this.method()}`; }

    if (method.bind(this)()) {
      return this.show();
    } else {
      return this.hide();
    }
  }

  hide() {
    return this.element.removeClass('visible ability-visible');
  }

  show() {
    return this.element.addClass('visible ability-visible');
  }

  method() {
    return this.element.data("ability");
  }

  isUser() {
    return this.currentUser.id;
  }

  isOwner() {
    return this.currentUser.id === parseInt(this.data("owner-id"));
  }

  data(param) {
    return this.element.data(param);
  }

  hasRole(...roles) {
    const results = roles.map(role => Array.from(this.currentUser.roles).includes(role));
    return Array.from(results).includes(true);
  }
});
Cls.initClass();
