// this is only for jQuery requests for Rails is in webpacker
$ = jQuery

class AjaxBeforeSendListender  {
  start() {
    $.ajaxPrefilter(this.onBeforeSend.bind(this))
  }

  onBeforeSend(options, originalOptions, xhr) {
    xhr.setRequestHeader('X-Variant', this.variant.content)
    xhr.setRequestHeader('X-CSRF-Token', this.token.content);
  }

  get variant() {
    return document.querySelector('meta[name=variant]')
  }

  get token() {
    return document.querySelector('meta[name=csrf-token]');
  }
}

const listender = new AjaxBeforeSendListender()
listender.start()
