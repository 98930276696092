// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import timeago from 'timeago.js'
import pl from 'timeago.js/locales/pl'
import { Controller } from "@hotwired/stimulus"

timeago.register('pl', pl);

export default class extends Controller {
  connect() {
    timeago().render(this.element, 'pl')
  }
}
