var ExternalLink  = initialize((function($) {
  // return class object
  var self = {
    defaultDomain: $("meta[name=default-domain]").attr("content"),

    initialize: function() {
      self.addObservers();
    },

    addObservers: function() {
      $(document).on("click", "a", function(event) {
        if (self.isExternal(this)) {
          //event.preventDefault()
          //self.track($(this).attr('href'));
        }
      });
    },

    isExternal: function(link) {
      var url = $(link).attr("href");

      if (!url) { return false; }
      if (!url.match(/https?:\/\/.*/)) { return false; }
      if (url.match(new RegExp(self.defaultDomain))) { return false; }

      return true;
    },

    track: function(url) {
      ga('send', 'event', 'ExternalLink', 'click', url , {
        nonInteraction: true,
        transport: 'beacon',
        'hitCallback': function() { document.location = url; }
      });
    }
  }
  return self;
})(jQuery));
