const $ = jQuery;

class FdbAsync {
  constructor(fdbQueue) {
    this.fdb = this.fdb.bind(this);
    this.fdbQueue = fdbQueue;
    this.sync();
  }

  fdb() {
    this.fdbQueue.push(arguments);
    return this.sync();
  }

  sync() {
    return (() => {
      let command;
      const result = [];
      while ((command = this.fdbQueue.shift())) {
        command = Array.prototype.slice.call(command);
        result.push(FdbAsyncCaller[command.shift()].apply(null, command));
      }
      return result;
    })();
  }
}

const fdbAsync = new FdbAsync(window.fdbQueue || []);
fdbAsync.sync;
window.fdb = fdbAsync.fdb;
