/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const $ = jQuery;

window.Content = class Content {

  update(html, anchor) {
    $("#content-fieldset").html(html);
    location.hash = "";
    window.scrollTo(0, 0);
    if (anchor) {
      return location.hash = "#item-" + anchor;
    }
  }
};
