/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const $ = jQuery;

class Scroller {
  static setup() {
    return $("[data-behavior~=scroll]").each(this.setupOne);
  }

  static setupOne(index, element) {
    return new Scroller(element);
  }

  constructor(element) {
    this.nextPage = this.nextPage.bind(this);
    this.prevPage = this.prevPage.bind(this);
    this.element = element;
    this.row = this.element.querySelector(".scroller-row");
    this.nextButton = this.element.querySelector(".scroller-next");
    this.prevButton = this.element.querySelector(".scroller-prev");

    this.addObservers();
  }

  addObservers() {
    this.nextButton.addEventListener("click", this.nextPage);
    return this.prevButton.addEventListener("click", this.prevPage);
  }

  nextPage() {
    return $(this.row).animate({scrollLeft: `+=${this.row.offsetWidth}`}, 300);
  }

  prevPage() {
    return $(this.row).animate({scrollLeft: `-=${this.row.offsetWidth}`}, 300);
  }

  scroll() {
    return $(this.row).animate({scrollLeft: "+=400"}, 500);
  }
}


$(() => Scroller.setup());
