//= require ./movie_picture_json_formatter
const $ = jQuery;

class Lightbox2MoviePictureHtmlFormatter extends Lightbox2MoviePictureJsonFormatter {

  constructor(index, item) {
    super(index,  $(item).data("json"));
  }
}

window.Lightbox2MoviePictureHtmlFormatter = Lightbox2MoviePictureHtmlFormatter;


