/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const $ = jQuery;

class Load {
  static initClass() {
  
    this.attachOne = event => {
      this.currentInstance = this.instance($(event.currentTarget));
      return this.currentInstance.load();
    };
  }
  static attach() {
    $(document).on("click", '[data-behavior~=load-on-click]', this.attachOne);
    return $(document).on("show.bs.dropdown", '[data-behavior~=load-on-show]', this.attachOne);
  }

  static instance(element) {
    if (!element.data('load')) { element.data('load', new Load(element)); }
    return element.data('load');
  }

  // Instance methods
  constructor(element) {
    this.onDone = this.onDone.bind(this);
    this.element = element;
  }

  load() {
    this.target().empty().addClass("loading");
    return $.ajax({
      url: this.url(),
      type: 'GET',
      dataType: this.type()
    }).done(this.onDone);
  }

  onDone(data) {
    console.log("done...");
    this.target().removeClass("loading");
    if (this.type() !== "html") { return; }
    this.target().html(data);
    return this.element.find("[autofocus]").first().focus();
  }

  url() {
    return this.element.data("load-url");
  }

  type() {
    return this.element.data("load-type") || "html";
  }

  target() {
    return $(this.element.data("load-target"));
  }
}
Load.initClass();

Load.attach();
