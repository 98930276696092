/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const $ = jQuery;

class Announcement {
  static initClass() {
    this.prototype.announcement = 'announcement';
    
  }

  constructor() {
    this.hideAndSave = this.hideAndSave.bind(this);
    this.addObservers();
    this.checkIfShow();
  }

  addObservers() {
    return this.container().on('click', '.close', this.hideAndSave);
  }

  hide() {
    return this.container().addClass("hidden d-none");
  }

  show() {
    return this.container().removeClass("hidden d-none");
  }

  hideAndSave() {
    this.hide();
    return this.saveAsHidden();
  }

  checkIfShow() {
    if (!this.hidden()) { return this.show(); }
  }

  saveAsHidden() {
    return $.cookie(this.announcement, this.id(), {expires: 365*10, path: '/', domain: this.cookieDomain()});
  }

  hidden() {
    return $.cookie(this.announcement) === this.id();
  }

  container() {
    return $('#announcement');
  }

  id() {
    return this.container().data("id") + "";
  }

  cookieDomain() {
    return $('meta[name=cookie-domain]').attr('content');
  }
}
Announcement.initClass();

$(() => new Announcement);
