/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const $ = jQuery;

const Cls = (window.Favourite = class Favourite {
  static initClass() {
    this.currentUser = null;
  
    this.refreshOne = (index, element) => {
      const currentInstance = this.instance($(element));
      return currentInstance.refresh();
    };
  }

  static add(kind, id)  {
    const f = this.favourites();

    if (f[kind] == null) { f[kind] = []; }
    return f[kind].push(id);
  }

  static remove(kind, id) {
    const f = this.favourites();

    if (f[kind]) {
      const index = f[kind].indexOf(id);
      if (index > -1) { return f[kind].splice(index, 1); }
    }
  }

  static favourites() {
    return this.currentUser.favourites;
  }

  static refresh() {
    if (this.currentUser) { return $("[data-behavior~=favourite]").each(this.refreshOne); }
  }

  static instance(element) {
    if (!element.data('favourite')) { element.data('favourite', new Favourite(element)); }
    return element.data('favourite');
  }

  constructor(element) {
    this.element = element;
  }

  favourites() {
    return Favourite.favourites();
  }

  markAsFavourite() {
    return this.element.addClass("favourite-yes");
  }

  markAsNotFavourite() {
    return this.element.removeClass("favourite-yes");
  }

  favouritesForKind() {
    return this.favourites()[this.kind()] || [];
  }

  inFavourites() {
    return $.inArray(this.id(), this.favouritesForKind()) >= 0;
  }

  refresh() {
    if (this.inFavourites()) {
      return this.markAsFavourite();
    } else {
      return this.markAsNotFavourite();
    }
  }

  id() {
    return parseInt(this.element.data('id').split(/-/)[1]);
  }

  kind() {
    return this.element.data('id').split(/-/)[0];
  }
});
Cls.initClass();

$(document).ready(() => Favourite.refresh());
document.addEventListener("fdb.userUpdate", function() {
  Favourite.currentUser = currentUser;
  return Favourite.refresh();
});
