import { Controller } from "@hotwired/stimulus"
import queryObject from "query-object"

export default class extends Controller {
  static values = {
    name: String,
  }

  connect () {
    if (this.param != null) {
      this.element.value = this.param
    }
  }

  get param() {
    return queryObject.parse(location.search)[this.nameValue]
  }
}
