/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const $ = jQuery;

$.fn.textareaInsert = function(value) {
  return this.each(function() {
    const textarea = new Textarea(this);
    return textarea.insert(value);
  });
};

window.Textarea = class Textarea {
  constructor(textarea) {
    this.textarea = $(textarea)[0];
  }

  value(value) {
    if (value) { this.textarea.value = value; }
    return this.textarea.value;
  }

  length() {
    return this.textarea.length;
  }

  selectionStart() {
    return this.textarea.selectionStart;
  }

  selectionEnd() {
    return this.textarea.selectionEnd;
  }

  focus() {
    return this.textarea.focus();
  }

  textBeforeSelection() {
    return this.value().substring(0, this.selectionStart());
  }

  textAfterSelection() {
    return this.value().substring(this.selectionEnd(), this.length());
  }

  insert(text) {
    text = text.replace("{{selection}}", this.selection());

    const cursorOffset = text.split("{{cursor}}")[0].length;
    text = text.replace("{{cursor}}", "");

    const newValue = this.textBeforeSelection() + text + this.textAfterSelection();
    const cursorPosition = this.selectionStart() + cursorOffset;

    this.value(newValue);
    this.moveCoursor(cursorPosition);
    return this.focus();
  }

  moveCoursor(position) {
    this.textarea.selectionStart = position;
    return this.textarea.selectionEnd = position;
  }

  moveCoursorToBeginingOfLine() {
    const textBefore = this.textBeforeSelection().split("\n");
    const offset = textBefore[textBefore.length-1].length;
    const coursorPosition = this.selectionStart() - offset;
    return this.moveCoursor(coursorPosition);
  }

  moveCoursorToEndOfLine() {
    const offset = this.textAfterSelection().split("\n")[0].length;
    const coursorPosition = this.selectionStart() + offset;
    return this.moveCoursor(coursorPosition);
  }

  selection() {
    return this.value().substring(this.textarea.selectionStart, this.textarea.selectionEnd);
  }
};

