/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const $ = jQuery;

class Lightbox2JsonFormatter extends Lightbox2Formatter {

  imageUrl() {
    return this.item.hd_url;
  }

  description() {
    return [this.dimensionWithSize(), this.user(), this.item.description];
  }

  thumbnailUrl() {
    return this.item.thumbnail_url;
  }

  dimensionWithSize() {
    const label = this.label("Rozmiar");
    const data = this.download(`${this.dimension()} (${this.size()})`);

    return `${label} ${data}`;
  }

  downloadUrl() {
    return this.item.url;
  }

  size() {
    return `${Math.round(this.item.size/1024)}kb`;
  }

  dimension() {
    return `${this.item.width}x${this.item.height}`;
  }

  download(text) {
    return `<a target=\"blank\" href=\"${this.downloadUrl()}\">${text}</a>`;
  }

  user() {
    let user = this.label("Dodający");
    if (this.item.user) {
      return user += ` <a target=\"blank\" href=\"${this.item.user.url}\">${this.item.user.name}</a>`;
    } else {
      return user += " Nieznany";
    }
  }
}


window.Lightbox2JsonFormatter = Lightbox2JsonFormatter;
