/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const $ = jQuery;

class ThumbnailSelector {
  constructor() {
    this.addObservers();
    this.markSelected();
  }

  select(event) {
    event.preventDefault();
    const target = $(event.currentTarget);
    $("#" + target.data("thumbnail")).val(target.data("id"));
    return this.markSelected();
  }

  markSelected() {
    return $("[data-thumbnail]").each((index, item) => {
      item = $(item);
      const selected = parseInt($("#" + item.data("thumbnail")).val());
      const current = item.data("id");

      if (selected === current) {
        return item.addClass("selected");
      } else {
        return item.removeClass("selected");
      }
    });
  }

  addObservers() {
    return $("body").on("click", "[data-thumbnail]", event => this.select(event));
  }
}


$(() => new ThumbnailSelector);
