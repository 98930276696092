/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const $ = jQuery;

class Modal {
  constructor() {}

  container() {
    return $("#modal");
  }

  show(content) {
    this.appendTemplateIfNotExists();
    this.updateContent(content);
    if (!this.container().is(":visible")) {
      return this.modal().show();
    }
  }

  hide() {
    this.appendTemplateIfNotExists();
    return this.modal().hide();
  }

  focus(selector) {
    return this.container().find(selector).focus();
  }

  appendTemplateIfNotExists() {
    if (this.container().length  === 0) { return $('body').append(this.template()); }
  }

  updateContent(content) {
    return this.container().html(content);
  }

  modal() {
   if (this._modal) {
     return this._modal;
   } else {
    return this._modal = new bootstrap.Modal("#modal", { focus: false });
  }
 }

  template() {
    return `\
<div class="modal" id="modal"></div>\
`;
  }
}


$(() => window.modal = new Modal());
