/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const $ = jQuery;

const submitFormEvent = new Event('submit', {bubbles: true, cancelable: true});

$(document).on("change", '[data-behavior~=submit-on-change]', function(event) {

  const {
    form
  } = event.currentTarget;
  $(form).append("<input type='hidden' name='submit_on_change' value='true'>");
  if (form.dataset.remote) {
    return form.dispatchEvent(submitFormEvent);
  } else {
    return form.submit();
  }
});

class SubmitOnKeyup {
  static setup() {
    return $(document).on("keyup", '[data-behavior~=submit-on-keyup]', this.onKeyup);
  }

  static onKeyup(event) {
    const submit = new SubmitOnKeyup($(event.currentTarget));
    return submit.call();
  }

  constructor(element) {
    this.submit = this.submit.bind(this);
    this.element = element;
  }

  call() {
    clearTimeout(this.element.data("timer"));
    return this.element.data('timer', setTimeout(this.submit, this.delay()));
  }

  form() {
    return this.element.closest("form");
  }

  submit() {
    if (this.form().data("remote")) {
      return this.form()[0].dispatchEvent(submitFormEvent);
    } else {
      return this.form().submit();
    }
  }

  delay() {
    return parseInt(this.element.data("delay") || 0);
  }
}

SubmitOnKeyup.setup();
