/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const $ = jQuery;

const Cls = (window.LinkHelper = class LinkHelper {
  static initClass() {
  
    this.attachOne = event => {
      event.preventDefault();
      const element = event.currentTarget;
  
      if (!this.instance(element)) {
        $(element).data('fdb.linkHelper', new LinkHelper(element));
      }
  
      return this.instance(element).toggle();
    };
  }

  static attach() {
    return $(document).on("click", '[data-link-helper]', this.attachOne);
  }

  static instance(element) {
    return $(element).data('fdb.linkHelper');
  }

  constructor(element) {
    this.observeResults = this.observeResults.bind(this);
    this.setQueryValue = this.setQueryValue.bind(this);
    this.focusOnQuery = this.focusOnQuery.bind(this);
    this.insertLink = this.insertLink.bind(this);
    this.title = this.title.bind(this);
    this.content = this.content.bind(this);
    this.element = $(element);
    this.popover = this.initializePopover();
    window.p = this.popover;
    this.observePopoverShow();
  }

  observePopoverShow() {
    this.element.one("shown.bs.popover", this.observeResults);
    this.element.on("shown.bs.popover", this.setQueryValue);
    return this.element.on("shown.bs.popover", this.focusOnQuery);
  }

  observeResults() {
    return this.tip().on("click", "[data-link-helper-insert]", this.insertLink);
  }

  tip() {
    return $(this.popover.tip);
  }

  toggle() {
    return this.popover.toggle();
  }

  selection() {
    return this.textarea().selection();
  }

  setQueryValue() {
    return this.query().val(this.selection());
  }

  id() {
    return this.element.attr('id') || this.assignId();
  }

  assignId() {
    return this.element.attr('id', this.generateId()).attr('id');
  }

  generateId() {
    return `link-helper-${(new Date).getTime()}`;
  }

  target() {
    return this.element.data('target');
  }

  initializePopover() {
    return new bootstrap.Popover(this.element[0], {
      trigger: 'manual',
      container: 'body',
      placement: 'bottom',
      html: true,
      title: this.title,
      content: this.content,
      template: this.template(),
      animation: false,
      fallbackPlacement: [],
      sanitizeFn: this.sanitizeFn
    }
    );
  }

  sanitizeFn(content) {
    return content;
  }

  template() {
    return `\
<div class="popover link-helper">
  <div class="arrow"></div>
  <div class="popover-title popover-header"></div>
  <div class="popover-content popover-body"></div>
</div>\
`;
  }

  focusOnQuery() {
    return setTimeout(
      () => this.query().focus(),
      0
    );
  }



  results() {
    return this.tip().find(".link-helper-results");
  }

  query() {
    return this.tip().find(".link-helper-query");
  }

  update(html) {
    return this.results().html(html);
  }

  textarea() {
    return new Textarea(this.target());
  }

  insertLink(event) {
    event.preventDefault();
    const link = $(event.currentTarget);

    const alt = this.textarea().selection();

    const tag = link.data("tag");
    let name = link.data("name");

    if (alt && (alt !== name)) { name = `(${alt}) ${name}`; }
    name = `_${name}_ (${tag})`;

    this.textarea().insert(name);
    return this.popover.toggle();
  }

  type() {
    return this.element.data("link-helper");
  }

  title() {
    return `\
<form action="/szukaj/link_helper" data-remote="true" method="get">
  <input type="hidden" name="type" value="${this.type()}">
  <input type="hidden" name="id" value="${this.id()}">
  <input type="text" name="query" class="form-control form-control-sm link-helper-query" placeholder="Wpisz frazę i naciśnij enter.." autocomplete="off">
</form>\
`;
  }
  content() {
    return `\
<div class="link-helper-results"></div>\
`;
  }
});
Cls.initClass();

LinkHelper.attach();
